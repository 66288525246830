import React from "react";
import FeedRequestItem from "../feedrequestitem";
import FeedRecommendationItem from "../feedrecommendationitem";

export default function FeedListContainer(props) {
  const { feedItems, isRecommendations } = props;

  return (
    <div className="bg-white mt-2">
      <div className="">
        {feedItems.map((item, i) => {
          if (item.type === "recommendation" || isRecommendations) {
            return <FeedRecommendationItem key={i} item={item} />;
          } else {
            return <FeedRequestItem key={i} item={item} />;
          }
        })}
      </div>
    </div>
  );
}
