import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShareDetails from "./ShareDetails";

function RequestDetails(props) {
  const [readMore, setReadMore] = useState(false);
  const { Username, LocationName, Details } = props.requestDetails;
  const trimmedDetails = Details.substring(0, 90);
  const linkName = readMore ? 'Read Less << ' : 'Read More >> ';

  const ReadMoreLink = () => <a className="text-indigo-400" onClick={() => { setReadMore(!readMore) }}><h2>{linkName}</h2></a>


  const UsernameLink = () => {
    return (
      <Link style={{ color: "#0645AD" }} to={`/user/${Username}`}>
        {"@" + Username + " "}
      </Link>
    );
  };
  return (
    <div className={"pt-2 px-2 sm:px-0 text-left"}>
      <p
        className="py-1 text-sm font-medium"
      >
        {props.results ? `Recommended places for ` : `Recommend places for `}
        {<UsernameLink />}
        {`around `}
      </p>
      <p
        className="py-1 text-2xl font-black"
      >
        {`${LocationName}`}
      </p>
      {Details && (
        <>
          <div className="inline text-md italic max-h-10">
            {readMore ? Details : trimmedDetails}
            {Details !== trimmedDetails && <ReadMoreLink/>}
          </div>
        </>
      )}
      <hr className="border-black-400 border-opacity-100 my-2"></hr>
      <ShareDetails requestDetails={props.requestDetails} />
      <hr className="border-black-400 border-opacity-100 my-2"></hr>
    </div>
  );
}

export default RequestDetails;
