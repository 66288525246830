import React from "react";
import { SparklesIcon } from "@heroicons/react/solid";
import useCheckMobileScreen from "../../utils/MobileCheck";

function PlaceDetails(props) {
  const { place } = props;
  const {
    website,
    rating,
    formatted_address,
    formatted_phone_number,
    name,
    photos,
  } = place;
  const isMobileView = useCheckMobileScreen();

  const defaultPhotoUrl =
    photos && photos.length > 0 ? photos[0].getUrl() : null;

  const renderDesktop = () => {
    return (
      <div className="overflow-y-auto pb-5">
        {defaultPhotoUrl && (
          <img
            style={{ maxHeight: "300px", margin: "auto" }}
            src={defaultPhotoUrl}
            alt={name}
          />
        )}
        <div className="px-6 pt-4">
          <div className="text-2xl text-left">{name}</div>
          {rating && (
            <div className="flex pt-2">
              <div className="pr-1 text-sm text-left">
                {`Google: ${rating} / 5.0`}
              </div>
              <SparklesIcon className="h-5 w-5 text-yellow-400" />
            </div>
          )}
          <div className={"pt-2 text-sm text-left"}>{formatted_address}</div>
          <div className={"pt-2 text-sm text-left text-blue-400"}>
            <a href={website}>{website}</a>
          </div>
          <div className={"pt-2 text-sm text-left text-blue-400"}>
            <a href={`tel:${formatted_phone_number}`}>
              {formatted_phone_number}
            </a>
          </div>
        </div>
        <div className="py-2">{props.children}</div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="overflow-y-auto pb-5">
        <div className="px-6 pt-4">
          <div className="text-2xl text-left">{name}</div>
          {rating && (
            <div className="flex pt-2">
              <div className="pr-1 text-sm text-left">
                {`Google: ${rating} / 5.0`}
              </div>
              <SparklesIcon className="h-5 w-5 text-yellow-400" />
            </div>
          )}
          <div className={"pt-2 text-sm text-left"}>{formatted_address}</div>
          <div className={"pt-2 text-sm text-left text-blue-400"}>
            <a href={website}>{website}</a>
          </div>
          <div className={"pt-2 text-sm text-left text-blue-400"}>
            <a href={`tel:${formatted_phone_number}`}>
              {formatted_phone_number}
            </a>
          </div>
        </div>
        <div className="py-2">{props.children}</div>
        {defaultPhotoUrl && (
          <img
            style={{ maxHeight: "300px", margin: "auto" }}
            src={defaultPhotoUrl}
            alt={name}
          />
        )}
      </div>
    );
  };

  return isMobileView ? renderMobile() : renderDesktop();
}

export default PlaceDetails;
