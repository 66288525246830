import React, { useContext, useState } from "react";
import { auth } from "../../firebase";
import UserContext from "../../contexts/UserContext";
import { useHistory } from "react-router";
import Logo from "../logo";
import { toast } from "react-toastify";
import { getUserDetails } from "../../api";

export default function SignIn(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { userDispatch } = useContext(UserContext);
  const signInWithEmailAndPasswordHandler = (event) => {
    event.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        try {
          const userDetailsResponse = await getUserDetails();
          if (userDetailsResponse.executed) {
            userDispatch({
              type: "login",
              data: {
                userAuth: auth.currentUser,
                userDets: userDetailsResponse.user,
                userRequests: userDetailsResponse.requests,
                userRecommendations: userDetailsResponse.recommendations,
                userFollowers: userDetailsResponse.followers,
                userFollowings: userDetailsResponse.followings,
              },
            });
            window.analytics.identify(userDetailsResponse.user.UserId, {
              DisplayName: userDetailsResponse.user.DisplayName,
              Username: userDetailsResponse.user.Username,
            });
          }
          if (!props.stayOnPageOnSubmit) history.push("/");
        } catch (e) {
          console.error(e);
          toast.error(e.message);
        }
      })
      .catch((error) => {
        console.error("Error signing in with password and email", error);
        toast.error(error.message);
      });
  };

  return (
    <div className="flex-1 flex flex-col py-5 px-10 sm:px-10 lg:flex-none lg:px-10 xl:px-10">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <Logo style={{ color: "black", margin: 0 }} />
        <div className="mt-6">
          <form action="#" method="POST" className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="space-y-2">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  placeholder="Password"
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div> */}
            <div>
              <button
                type="submit"
                onClick={(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Login
              </button>
            </div>
            <div>
              <button
                type="submit"
                onClick={() => props.toggleForm()}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 bg-opacity-75 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create an Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
