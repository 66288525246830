import { isEmpty } from "lodash";
import React, { useContext } from "react";
import UserContext from "../../contexts/UserContext";

const getInitials = (userDetails) => {
  const displayName = userDetails?.DisplayName;
  const username = userDetails?.Username;
  if (displayName) {
    const splitDisplayName = displayName.split(" ");
    if (splitDisplayName.length > 1) {
      return `${splitDisplayName[0].charAt(0)}${splitDisplayName[1].charAt(0)}`;
    } else return splitDisplayName[0].charAt(0);
  }
  return username.charAt(0);
};

function DefaultProfileImage(props) {
  const { user } = useContext(UserContext);
  const userDets = props.userDets ? props.userDets : user?.userDets;
  if (isEmpty(userDets)) return <></>;

  const initials = getInitials(userDets);

  if (props.profileHeader) {
    return (
      <div className="inline-block flex justify-center items-center text-4xl leading-20 text-white bg-indigo-400 rounded-full w-20 h-20 align-middle text-center">
        {initials}
      </div>
    );
  }
  return (
    <div className="inline-block leading-9 text-white bg-indigo-400 rounded-full w-9 h-9 align-middle text-center">
      {initials}
    </div>
  );
}

export default DefaultProfileImage;
