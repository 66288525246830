import React, { useState } from "react";
import SignUp from "../signup";
import SignIn from "../signin";

function AuthModal(props) {
  const [signUp, setSignUp] = useState(true);

  return (
    <>
      {signUp ? (
        <SignUp
          stayOnPageOnSubmit={true}
          customToggle={true}
          toggleForm={() => setSignUp((prevSignUp) => !prevSignUp)}
          requestDetails={props.requestDetails}
          referrer={props.referrer}
        ></SignUp>
      ) : (
        <SignIn
          stayOnPageOnSubmit={true}
          customToggle={true}
          toggleForm={() => setSignUp((prevSignUp) => !prevSignUp)}
          requestDetails={props.requestDetails}
          referrer={props.referrer}
        ></SignIn>
      )}
    </>
  );
}

export default AuthModal;
