import { auth } from "../firebase";
import {
  GET_REQUEST_ENDPOINT,
  CREATE_RECOMMENDATION_ENDPOINT,
  DELETE_RECOMMENDATION_ENDPOINT,
  GET_USER_DETAILS_ENDPOINT,
  CHECK_IF_USERNAME_EXISTS_ENDPOINT,
  CREATE_USER_ENDPOINT,
  GET_REQUEST_RESPONSES_ENDPOINT,
  GET_USER_DETAILS_BY_USERNAME_ENDPOINT, 
  FOLLOW_USER_ENDPOINT,
  UNFOLLOW_USER_ENDPOINT,
  GET_FEED_ENDPOINT,
  UPDATE_RECOMMENDATION_DETAILS_ENDPOINT,
  CREATE_REQUEST_ENDPOINT,
} from "../constants/constants";

const buildPostRequestOptions = async (body, token) => {
  let bearerToken = token ? token : await auth?.currentUser?.getIdToken();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${bearerToken}`,
    },
  };
  if (body) requestOptions.body = JSON.stringify(body);
  return requestOptions;
};

// User

export const getUserDetails = async (username) => {
  try {
    let response;
    const url = username ? `${GET_USER_DETAILS_BY_USERNAME_ENDPOINT}?username=${username}` : GET_USER_DETAILS_ENDPOINT;
    if (username) response = await fetch(url);
    else {
      const requestOptions = await buildPostRequestOptions();
      response = await fetch(url, requestOptions);
    }
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const checkIfUsernameExists = async (username) => {
  try {
    const response = await fetch(
      `${CHECK_IF_USERNAME_EXISTS_ENDPOINT}?username=${username}`
    );
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createUser = async (user, token) => {
  try {
    const requestOptions = await buildPostRequestOptions(user);
    const response = await fetch(CREATE_USER_ENDPOINT, requestOptions);
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const followUser = async (userId) => {
  try {
    const requestOptions = await buildPostRequestOptions({
      following_id: userId
    });
    const response = await fetch(FOLLOW_USER_ENDPOINT, requestOptions);
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const unfollowUser = async (userId) => {
  try {
    const requestOptions = await buildPostRequestOptions({
      following_id: userId
    });
    const response = await fetch(UNFOLLOW_USER_ENDPOINT, requestOptions);
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

// Requests

export const createRequest = async (request) => {
  try {
    const requestOptions = await buildPostRequestOptions(request);
    const response = await fetch(
      CREATE_REQUEST_ENDPOINT,
      requestOptions
    );
    return await response.json();
  } catch (e) {
    console.error(e);
  }
}

export const getRequestDetails = async (requestId) => {
  try {
    const response = await fetch(
      `${GET_REQUEST_ENDPOINT}?request_id=${requestId}`
    );
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

// Request Responses

export const getRequestResponses = async (requestId) => {
  try {
    const response = await fetch(
      `${GET_REQUEST_RESPONSES_ENDPOINT}?request_id=${requestId}`
    );
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};
// Recommendation

export const createOrUpdateRecommendation = async (recommendation) => {
  try {
    const requestOptions = await buildPostRequestOptions(recommendation);
    const response = await fetch(
      CREATE_RECOMMENDATION_ENDPOINT,
      requestOptions
    );
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

export const deleteRecommendation = async (recommendation) => {
  try {
    const requestOptions = await buildPostRequestOptions(recommendation);
    const response = await fetch(
      DELETE_RECOMMENDATION_ENDPOINT,
      requestOptions
    );
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

export const updateRecommendationDetails = async (recommendation) => {
  try {
    const requestOptions = await buildPostRequestOptions(recommendation);
    const response = await fetch(
      UPDATE_RECOMMENDATION_DETAILS_ENDPOINT,
      requestOptions
    );
    return await response.json();
  } catch(e) {
    console.error(e);
  }
}

// Feed

export const getFeed = async() => {
  try {
    try {
      const response = await fetch(
        `${GET_FEED_ENDPOINT}`
      );
      return await response.json();
    } catch (e) {
      console.error(e);
    }
  } catch(e) {
    console.error(e);
  }
}