import React, { useState, useEffect, useCallback, useContext } from "react";
import Maps from "../../components/map";
import MapDrawer from "../../components/mapdrawer";
import { useParams } from "react-router-dom";
import BackButton from "../../components/auth/BackButton";
import PlaceDetails from "../../components/details/PlaceDetails";
import {
  createOrUpdateRecommendation,
  deleteRecommendation,
  getRequestDetails,
  getRequestResponses,
} from "../../api";
import { StarIcon as SolidStar } from "@heroicons/react/solid";
import { PencilAltIcon as SolidPencil } from "@heroicons/react/solid";
import UserContext from "../../contexts/UserContext";
import Modal from "../../components/modal";
import AuthModal from "../../components/modal/AuthModal";
import {
  VIEW_REQUEST_TYPE_NOTES,
  VIEW_REQUEST_TYPE_RECS,
} from "../../constants/constants";
import UserList from "../../components/list/UserList";
import NotesList from "../../components/list/NotesList";
import Recommend from "../../components/details/Recommend";
import { isEmpty } from "lodash";

let service;

const ViewRecommendations = (props) => {
  return (
    <div className="items-center mr-3 text-indigo-500 hover:text-indigo-600">
      <button
        onClick={props.onClickRecs}
        className="flex text-center items-center font-bold"
      >
        <span className="flex text-sm font-bold ">
          <SolidStar className="inline mr-1 h-5 w-5" aria-hidden="true" />
          {props.numOfRecommendations} Rec's
        </span>
      </button>
    </div>
  );
};

const ViewNotes = (props) => {
  return (
    <div className="items-center text-indigo-500 hover:text-indigo-600">
      <button onClick={props.onClickNotes}>
        <span className="flex text-sm font-bold ">
          <SolidPencil className="inline h-5 w-5 mr-1" />
          {props.numOfNotes} Notes
        </span>
      </button>
    </div>
  );
};

const ViewDetails = (props) => {
  return (
    <div className="flex inline px-5">
      <ViewRecommendations {...props} />
      <ViewNotes {...props} />
    </div>
  );
};

function PlaceDetailsPage() {
  const { locationId, requestId } = useParams();
  const [place, setPlace] = useState();
  const [requestDetails, setRequestDetails] = useState();
  const [placeRecDetails, setPlaceRecDetails] = useState();
  const [isModalOpen, setModalState] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const { user, userDispatch } = useContext(UserContext);
  const [modalType, setModalType] = useState();
  const [expandMobileDrawer, setExpandMobileDrawer] = useState(false);
  const isLoggedIn = !isEmpty(user.userDets);
  const mapRef = React.useRef();
  const hasRecommended = placeRecDetails?.users?.includes(
    user.userDets?.Username
  );

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (!(isModalOpen || showAuthModal)) {
      setModalType(false);
      setExpandMobileDrawer(false);
    }
  }, [isModalOpen, showAuthModal]);

  useEffect(async () => {
    const requestDetailsResponse = await getRequestDetails(requestId);
    if (requestDetailsResponse && requestDetailsResponse.executed)
      setRequestDetails(requestDetailsResponse.request);
    if (locationId) {
      service = new window.google.maps.places.PlacesService(mapRef.current);
      const request = {
        placeId: locationId,
        fields: [
          "website",
          "rating",
          "formatted_address",
          "formatted_phone_number",
          "name",
          "photos",
          "geometry",
        ],
      };

      service.getDetails(request, (response, status) => {
        if (status === "OK") {
          setPlace(response);
        }
      });

      const requestResponses = await getRequestResponses(requestId);
      if (requestResponses) {
        const responsesArray = Object.values(requestResponses.output);
        const placeRec = responsesArray.find(
          (response) => response.LocationId === locationId
        );
        setPlaceRecDetails(placeRec);
      }
    }
  }, [locationId, requestId]);

  const RequestHeader = () => {
    return (
      <>
        <BackButton position="relative" />
        <div className="text-center text-2xl py-0">
          {requestDetails?.LocationName}
        </div>
        <p className="text-center pb-1 text-sm text-black-300 italic px-5">
          {requestDetails?.Details}
        </p>
      </>
    );
  };

  const renderModal = useCallback(() => {
    const usernames = placeRecDetails.details_list.map(
      (obj) => Object.keys(obj)[0]
    );
    const enableEdit = Boolean(
      user.userRecommendations.find(
        (recommendation) =>
          recommendation.LocationId === locationId &&
          recommendation.RequestId === requestId
      )
    );

    return (
      <div className="py-10 mx-8">
        <div className="text-left text-3xl">{placeRecDetails.LocationName}</div>
        <div className="text-left text-md text-gray-400">
          {placeRecDetails.Address}
        </div>
        {modalType === VIEW_REQUEST_TYPE_RECS && (
          <UserList headerTitle={"Recommended by:"} usernames={usernames} />
        )}
        {modalType === VIEW_REQUEST_TYPE_NOTES && (
          <NotesList
            requestId={requestId}
            locationId={locationId}
            notes={placeRecDetails.details_list}
            enableEdit={enableEdit}
          />
        )}
      </div>
    );
  }, [user, placeRecDetails, requestId, modalType]);

  const viewModal = useCallback(
    (type) => {
      setModalState(true);
      setModalType(type);
      setExpandMobileDrawer(true);
    },
    [setModalType, setModalState]
  );

  const recommend = () => {
    setPlaceRecDetails({
      ...placeRecDetails,
      users: [...placeRecDetails.users, user.userDets.Username],
      details_list: [
        ...placeRecDetails.details_list,
        { [user.userDets.Username]: "" },
      ],
      num_recommendations: placeRecDetails.num_recommendations + 1,
      user_recommendation: true,
    });

    const body = {
      request_id: requestId,
      location_name: placeRecDetails.LocationName,
      location_id: placeRecDetails.LocationId,
      address: placeRecDetails.Address,
      latitude: placeRecDetails.Latitude,
      longitude: placeRecDetails.Longitude,
      details: "",
    };

    createOrUpdateRecommendation(body).then((response) => {
      if (response.executed) {
        userDispatch({
          type: "newRecommendation",
          data: { userRecommendation: response.rec },
        });
      }
    });
  };

  const unrecommend = () => {
    setPlaceRecDetails({
      ...placeRecDetails,
      users: placeRecDetails.users.filter(
        (username) => username !== user.userDets.Username
      ),
      details_list: placeRecDetails.details_list.filter(
        (detail) => Object.keys(detail)[0] !== user.userDets.Username
      ),
      num_recommendations: placeRecDetails.num_recommendations - 1,
      user_recommendation: false,
    });

    const body = {
      location_id: locationId,
      request_id: requestId,
    };

    deleteRecommendation(body).then((response) => {
      if (response.executed) {
        userDispatch({
          type: "removeRecommendation",
          data: {
            userRecommendation: {
              LocationId: locationId,
              RequestId: requestId,
            },
          },
        });
      }
    });
  };

  const onRecommend = useCallback(() => {
    if (placeRecDetails) {
      if (hasRecommended) {
        unrecommend();
      } else recommend();
    }
  }, [placeRecDetails, user]);

  return (
    <>
      <Modal open={showAuthModal} setOpen={setAuthModal}>
        <AuthModal />
      </Modal>

      {isModalOpen && (
        <Modal open={isModalOpen} setOpen={setModalState}>
          {renderModal()}
        </Modal>
      )}

      <div className="xl:hidden">
        <RequestHeader />
      </div>

      <div className="xl:hidden">
        <MapDrawer shouldNotExpand={expandMobileDrawer} unexpandedHeight={200}>
          {place && (
            <>
              <PlaceDetails place={place}>
                {placeRecDetails && (
                  <ViewDetails
                    numOfRecommendations={placeRecDetails?.num_recommendations}
                    numOfNotes={
                      Object.keys(placeRecDetails?.details_list).length
                    }
                    onClickRecs={() => viewModal(VIEW_REQUEST_TYPE_RECS)}
                    onClickNotes={() => viewModal(VIEW_REQUEST_TYPE_NOTES)}
                  />
                )}
              </PlaceDetails>
              {isLoggedIn && (
                <Recommend
                  hasRecommended={hasRecommended}
                  onClick={onRecommend}
                />
              )}
            </>
          )}
        </MapDrawer>
      </div>

      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          <Maps onMapLoad={onMapLoad} selectedPlace={place}></Maps>
        </main>
        <aside className="hidden relative pb-10 xl:order-first w-2/5 xl:flex xl:flex-col flex-shrink-0 w-1/4 border-r border-gray-200">
          <RequestHeader />
          {requestDetails && (
            <hr className="border-grey-300 border-opacity-100 mx-4 my-2" />
          )}
          {place && (
            <PlaceDetails place={place}>
              {placeRecDetails && (
                <ViewDetails
                  numOfRecommendations={placeRecDetails?.num_recommendations}
                  numOfNotes={
                    placeRecDetails?.details_list.filter(
                      (detail) => Object.values(detail) != ""
                    ).length
                  }
                  onClickRecs={() => viewModal(VIEW_REQUEST_TYPE_RECS)}
                  onClickNotes={() => viewModal(VIEW_REQUEST_TYPE_NOTES)}
                />
              )}
            </PlaceDetails>
          )}
          {isLoggedIn && (
            <Recommend hasRecommended={hasRecommended} onClick={onRecommend} />
          )}
        </aside>
      </div>
    </>
  );
}

export default PlaceDetailsPage;
