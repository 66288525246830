import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DuplicateIcon, MailIcon } from "@heroicons/react/outline";
import UserContext from "../../contexts/UserContext";

function ShareDetails(props) {
  const { user } = useContext(UserContext);
  const { Username, LocationName, Details, RequestId } = props.requestDetails;
  const requestDetails = props.requestDetails;
  const shareUrl = `https://youtellme.io/recommend/${RequestId}`;
  return (
    <div className="grid grid-cols-4 gap-2 my-3">
      <div className="col-span-1 text-center justify-center flex flex-col">
        <a
          rel="noreferrer"
          href={"https://twitter.com/intent/tweet?text=".concat(
            encodeURIComponent(
              `${
                user.userDets?.Username === Username
                  ? "I'm looking for recs in"
                  : "My friend ".concat(Username, " is looking for recs in")
              } ${LocationName}!\n"${Details}"\n\nShoot over your best recs here: ${shareUrl}\n\nPowered by @youtellmesocial`
            )
          )}
          target="_blank"
        >
          <button className="block-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-twitter hover:bg-opacity-75 focus:outline-none">
            <span className="block text-xs">
              Tweet{" "}
              <FontAwesomeIcon className="ml-1" icon={["fab", "twitter"]} />
            </span>
          </button>
        </a>
      </div>
      {/* <div className=" text-center justify-center flex flex-col px-3">
          <button className="block-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-facebook hover:bg-opacity-125 focus:outline-none">
            <span className="block justify-around text-xs">
              Share{" "}
              <FontAwesomeIcon className="ml-1" icon={["fab", "facebook-f"]} />
            </span>
          </button>
        </div> */}
      <div className="col-span-1 text-center justify-center flex flex-col">
        <a
          href={"sms:".concat(
            "&body=",
            encodeURIComponent(
              `${
                user.userDets?.Username === Username
                  ? "I'm looking for recs in"
                  : "My friend ".concat(Username, " is looking for recs in")
              } ${LocationName} - ${Details}${"\n"}Could you please shoot over your best recs @ ${shareUrl}? Thanks! :)`
            )
          )}
        >
          <button className="block-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-500 hover:bg-opacity-75 focus:outline-none">
            <span className="block text-xs">
              {"SMS "}
              <FontAwesomeIcon className="ml-1" icon={"comment"} />
            </span>
          </button>
        </a>
      </div>

      <div className="col-span-1 text-center justify-center flex flex-col">
        <a
          href={"mailto:".concat(
            "?subject=",
            encodeURIComponent(`${LocationName} Recs?`),
            "&body=",
            encodeURIComponent(
              `${
                user.userDets?.Username === Username
                  ? "I'm looking for recs in"
                  : "My friend ".concat(Username, " is looking for recs in")
              } ${LocationName}!\n"${Details}"\n\nShoot over your best recs here: ${shareUrl}\n\nPowered by youtellme`
            )
          )}
          rel="noreferrer"
          target="_blank"
        >
          <button className="block-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-facebook focus:outline-none">
            <span className="block text-xs">
              {"Email "}
              <MailIcon className="inline h-4 w-4" />
            </span>
          </button>
        </a>
      </div>
      <div className="col-span-1 text-center justify-center flex flex-col">
        <a rel="noreferrer" target="_blank">
          <button
            onClick={() => {
              navigator.clipboard.writeText(shareUrl);
              window.analytics.track("Share Attempt");
            }}
            className="block-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white text-indigo-700 bg-indigo-200 hover:bg-indigo-300 focus:outline-none"
          >
            <span className="block text-xs">
              {"Copy "}
              <DuplicateIcon className="inline h-4 w-4" />
            </span>
          </button>
        </a>
      </div>
    </div>
  );
}

export default ShareDetails;
