import React from "react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

function BackButton(props) {
  const navigate = useHistory();


  return (
    <div>
      <button
        type="submit"
        onClick={() => {
          navigate.goBack()
        }}
        className="py-1 px-4 border border-transparent top-2 text-md"
        style={{position: props.position ? props.position : "absolute"}}
      >
        <ChevronDoubleLeftIcon className="h-6 w-6 text-black-400"/>
    </button>
    </div>
  )
}

export default BackButton;