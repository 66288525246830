import React from "react";
import { Link } from "react-router-dom";
import DefaultProfileImage from "../defaultprofileimage";

function UserList(props) {
  const { usernames, headerTitle } = props;

  return (
    <>
      <div className="text-left py-3 text-2xl text-black-400">{headerTitle}</div>
      <ul className="divide-y divide-gray-200">
        {usernames.map((username, i) => (
          <li key={i} className="py-4">
            <Link to={`/user/${username}`} className="flex space-x-3 cursor-pointer">
              <DefaultProfileImage userDets={{Username: username}}/>
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium">{username}</h3>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </>
  )

}

export default UserList;