import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { LocationMarkerIcon } from "@heroicons/react/solid";

export default function RequestItem(props) {
  return (
    <div className="mx-4 mb-4">
      <Link to={`/request/${props.request.RequestId}`}>
        <div className="grid grid-cols-3 gap-2 mt-2">
          <div className="col-span-2 bg-opacity-75 text-left justify-center flex flex-col px-3">
            <span className="block font-bold text-md">
              {props.request.LocationName}
            </span>
          </div>
          <div className="bg-opacity-75 text-right justify-center flex flex-col px-3">
            <span className="block text-black-600 text-sm">
              {moment(new Date(Date.parse(props.request.CreateTime))).fromNow()}
            </span>
          </div>
        </div>
        {props.request?.Details && (
          <div className="grid grid-cols-1 gap-2 my-1.5">
            <div className="bg-opacity-75 text-left justify-center flex flex-col px-3">
              <span className="block text-black-600 text-sm">
                {props.request.Details}
              </span>
            </div>
          </div>
        )}
        {props.request.Recommendations > 0  && (
          <div className="mb-4 px-2">
            <p className="mt-2 flex items-center text-sm text-indigo-500 sm:mt-0">
              <LocationMarkerIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-indigo-500"
                aria-hidden="true"
              />
              {props.request.Recommendations}{" "}
              {props.request.Recommendations > 1 ? "Rec's" : "Recommendation"}
            </p>
          </div>
        )}
      </Link>
    </div>
  );
}
