import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function FeedRequestItem(props) {
  const RequesterUsernameLink = () => {
    return (
      <Link
        style={{ color: "#0645AD" }}
        to={`/user/${props.item.RequesterUsername}`}
      >
        {"@" + props.item.RequesterUsername + " "}
      </Link>
    );
  };
  return (
    <div>
      <Link to={`/request/${props.item.RequestId}`}>
        <div className="bg-indigo-50  px-1 mx-3 pb-2 pt-1">
          <div className="py-1">
            <div className="grid grid-cols-3 gap-1">
              <div className="col-span-2 text-left justify-center flex flex-col pl-3">
                <span className="block text-black font-bold text-xs">
                  <RequesterUsernameLink /> is looking for recs!
                </span>
              </div>
              <div className="col-span-1 text-right justify-center flex flex-col pr-3">
                <span className="block text-gray-500 text-xs truncate">
                  {moment(
                    new Date(Date.parse(props.item.UpdateTime))
                  ).fromNow()}{" "}
                </span>
              </div>
            </div>
          </div>
          {/* <hr className="border-gray-300 border-opacity-50 mx-3 mb-1"></hr> */}
          <div>
            <div className="grid grid-cols-1">
              <div className=" text-left justify-center flex flex-col px-3">
                <span className="block text-black font-medium text-md truncate">
                  {props.item.LocationName}
                </span>
              </div>
              <div className=" text-left justify-center flex flex-col px-6 pt-1">
                <span className="block text-black text-xs italic">
                  {props.item.Details}
                </span>
              </div>
              {/* <div className=" text-right justify-center flex flex-col px-3">
                <span className="block text-gray-500 text-xl truncate">
                  👉{" "}
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </Link>
      <hr className="border-indigo-200 bg-gray-50 mx-3"></hr>
    </div>
  );
}
