import React, { useContext } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/Tailwind";
import UserContext from "../../contexts/UserContext";
import { auth } from "../../firebase";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { HandIcon, HomeIcon, UserIcon } from "@heroicons/react/outline";
import youtellmelogo from "../../assets/youtellme.png";
import DefaultProfileImage from "../defaultprofileimage";

function Navbar() {
  const { user, userDispatch } = useContext(UserContext);
  const history = useHistory();
  const isLoggedIn = !isEmpty(user.userAuth);
  const username = user?.userDets?.Username;

  function handleLogout(e) {
    auth.signOut().then(() => {
      userDispatch({ type: "logout" });
      history.push("/");
    });
  }

  return (
    <Disclosure as="nav" className="bg-white shadow flex-shrink-0">
      {({ open }) => (
        <>
          <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="flex-1 flex sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to={"/"}>
                    <img
                      className="block lg:hidden h-12 w-auto"
                      src={youtellmelogo}
                      alt="you tell me"
                    />
                    <img
                      className="hidden lg:block h-12 w-auto"
                      src={youtellmelogo}
                      alt="you tell me"
                    />
                  </Link>
                </div>
                {isLoggedIn ? (
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 px-8">
                    <Link
                      to="/"
                      className="border-transparent text-black-500 hover:border-transparent-300 hover:text-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-md font-base"
                    >
                      <HomeIcon
                        className="inline mr-1 h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                      <div className="blur"></div>
                      <div className="blur">Home</div>
                    </Link>
                    <Link
                      to={`/user/${username}`}
                      className="border-transparent text-black-500 hover:border-transparent-300 hover:text-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-md font-base"
                    >
                      <UserIcon
                        className="inline mr-1 h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                      <div className="blur"></div>
                      <div className="blur">My Profile</div>
                    </Link>
                    <Link
                      to="/request"
                      className="border-transparent text-black-500 hover:border-transparent-300 hover:text-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-md font-base"
                    >
                      <HandIcon
                        className="inline mr-1 h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                      <div className="blur">Ask for Recs</div>
                    </Link>
                  </div>
                ) : (
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8 px-8">
                    <Link
                      to="/auth/signup"
                      className="border-transparent text-black-500 hover:border-transparent-300 hover:text-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-md font-base"
                    >
                      <HandIcon
                        className="inline mr-1 h-5 w-5 text-indigo-500"
                        aria-hidden="true"
                      />
                      <div className="blur">Ask for Recs</div>
                    </Link>
                  </div>
                )}
              </div>

              {!isLoggedIn && (
                <>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                      onClick={() => history.push("/auth/signin")}
                      className="bg-white px-4 py-2 rounded text-sm text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign In
                    </button>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
                    <button
                      onClick={() => history.push("/auth/signup")}
                      className="bg-indigo-400 py-2 px-4 rounded text-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-white py-2"
                    >
                      Sign Up
                    </button>
                  </div>
                </>
              )}
              {isLoggedIn && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <DefaultProfileImage />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            style={{ zIndex: 100000 }}
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={`/`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Home
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={`/user/${username}`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Your Profile
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={`/request`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Ask for Recs
                                </Link>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Settings
                                </a>
                              )}
                            </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    handleLogout();
                                  }}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 w-full text-left py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
