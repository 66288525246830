import React, { useState, useContext, useEffect } from "react";
import Maps from "../../components/map";
import UserContext from "../../contexts/UserContext";
import ProfileHeader from "../../components/profileheader";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../api";
import { mapUserDetailsToUserContext } from "../../mapper";
import Spinner from "../../components/spinner";
import { toast } from "react-toastify";
import RequestList from "../../components/list/RequestList";
import "./Profile.css";
import { classNames } from "../../utils/Tailwind";
import { QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/solid'
import FeedListContainer from "../../components/feedlistcontainer";
import { followUser, unfollowUser } from "../../api";

const tabs = [
  { name: 'Requests', title: 'Requests', icon: QuestionMarkCircleIcon },
  { name: 'Recs', title: 'Recs', icon: StarIcon },
];

function Profile() {
  const { username } = useParams();
  const { user, userDispatch } = useContext(UserContext);
  const [userProfile, setUserProfile] = useState();
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);


  const follow = async () => {
    try {
      const followResponse = await followUser(userProfile.userDets.UserId);
      if (followResponse && followResponse.executed) {
        const userFollower = {
          UserId: user.userDets.UserId,
          Username: user.userDets.Username,
          DisplayName: user.userDets.DisplayName,
        }
        setUserProfile({
          ...userProfile,
          userFollowers: userProfile.userFollowers.concat(userFollower)
        });

        userDispatch({
          type: "follow",
          data: {
            userFollowing: {
              UserId: userProfile.userDets.UserId,
              Username: userProfile.userDets.Username,
              DisplayName: userProfile.userDets.DisplayName,
            }
          },
        });

      }
    } catch (e) {
      console.error(e);
    }
  };

  const unfollow = async () => {
    try {
      const unfollowResponse = await unfollowUser(userProfile.userDets.UserId);
      if (unfollowResponse && unfollowResponse.executed) {
        setUserProfile({
          ...userProfile,
          userFollowers: [
            ...userProfile.userFollowers.filter(x => x.UserId !== user.userDets.UserId)
          ]
        });

        userDispatch({
          type: "unfollow",
          data: {
            userFollowing: {
              UserId: userProfile.userDets.UserId,
              Username: userProfile.userDets.Username,
              DisplayName: userProfile.userDets.DsiplayName,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(async () => {
    if (username?.toLowerCase()) {
      try {
        const userDetailsResponse = await getUserDetails(
          username?.toLowerCase()
        );
        if (userDetailsResponse.executed)
          setUserProfile(mapUserDetailsToUserContext(userDetailsResponse));
        else
          toast.error(`${username?.toLowerCase()} not found`, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const userDetailsResponse = await getUserDetails(
          user.userDets.Username
        );
        if (userDetailsResponse.executed)
          setUserProfile(mapUserDetailsToUserContext(userDetailsResponse));
        else
          toast.error(`${username?.toLowerCase()} not found`, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
      } catch (e) {
        console.error(e);
      }
    }
  }, [username]);

  if (!userProfile) return <Spinner />;

  return (
    <>
      <div className="flex-1 relative z-0 flex overflow-hidden grid grid-cols-10">
        <main className="hidden lg:flex lg:col-span-7 flex-1 relative z-0 focus:outline-none xl:order-last">
          {/* Start main area*/}
          <Maps
            userPlaces={userProfile.userRecommendations}
            userRequests={userProfile.userRequests}
          >
          </Maps>
          {/* End main area */}
        </main>
        <aside className="col-span-10 lg:col-span-3 lg:order-first flex flex-col flex-shrink-0 flex-grow-1 border-r border-gray-200 overflow-y-auto">
          <ProfileHeader follow={follow} unfollow={unfollow} userProfile={userProfile} />
          <div className="px-4 py-2">
            <div className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
              {tabs.map((tab, tabIdx) => (
                <button
                  onClick={() => setSelectedTab(tab.name)}
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.name === selectedTab ? 'bg-indigo-500' : 'hover:text-gray-700',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center focus:z-10'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  <span
                    className="justify-center items-center flex"
                  >{<tab.icon
                    className={classNames(
                      tab.name === selectedTab ? 'text-white' : 'text-gray-400 group-hover:text-gray-500',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tab.name === selectedTab ? 'bg-indigo-500' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5'
                    )}
                  />
                </button>
              ))}
            </div>
          </div>
          {selectedTab === tabs[0].name && <RequestList requests={userProfile?.userRequests} />}
          {selectedTab === tabs[1].name && <FeedListContainer isRecommendations={true} feedItems={[...userProfile?.userRecommendations].reverse()} />}
        </aside>
      </div>

    </>
  );
}
export default Profile;
