import React, { useCallback } from "react";
import { MinusIcon } from "@heroicons/react/solid";
import Notes from "../../pages/recommend/Notes";

function PlacesList(props) {
  const { places, onRemove, toggleNotes, onSelectPlace } = props;

  const handleRemove = useCallback(
    (i) => {
      onRemove(i);
    },
    [onRemove]
  );

  const handleOnClick = useCallback(
    (place) => {
      if (onSelectPlace) {
        onSelectPlace(place);
      }
    },
    [onSelectPlace]
  );

  return (
    <div className="flow-root mt-6">
      <ul className="-my-5 divide-y text-left divide-gray-200">
        {places.map((place, i) => (
          <li key={i} className="py-5 list-none">
            <div className="flex items-center space-x-4">
              <div className="flex-1 min-w-0">
                <div
                  className="cursor-pointer"
                  onClick={handleOnClick.bind(null, place)}
                >
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {place.name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {place.formatted_address}
                  </p>
                </div>
                <Notes hasNotes={!!place.note} onClick={() => toggleNotes(i)} />
              </div>
              <div>
                <button
                  onClick={handleRemove.bind(null, i)}
                  className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                >
                  <MinusIcon className="h-5 w-5 text-red-400" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PlacesList;
