import { isFunction } from "lodash";

export const mapPlaceToRecommendationInput = (place) => {
  const lat = place?.geometry?.location?.lat;
  const lng = place?.geometry?.location.lng;
  return {
    location_name: place.name,
    location_id: place.place_id,
    address: place.formatted_address,
    latitude: isFunction(lat) ? lat() : lat,
    longitude: isFunction(lng) ? lng() : lng,
    details: place.note ? place.note : ""
  }
}

export const mapRecommendationInputToPlace = (recommendationInput) => {
  return {
    name: recommendationInput.LocationName,
    place_id: recommendationInput.LocationId,
    formatted_address: recommendationInput.Address,
    note: recommendationInput.Details,
    geometry: {
      location: {
        lat: recommendationInput.Latitude,
        lng: recommendationInput.Longitude
      }
    }
  }
}

export const mapUserDetailsToUserContext = (userDetails) => {
  return {
    userDets: userDetails.user,
    userRequests: userDetails.requests,
    userRecommendations: userDetails.recommendations,
    userFollowers: userDetails.followers,
    userFollowings: userDetails.followings,
  }
}