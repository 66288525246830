import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const RequesterUsernameLink = (props) => {
  const { requesterUsername } = props;
  return (
    <Link
      style={{ color: "#0645AD" }}
      to={`/user/${requesterUsername}`}
    >
      {"@" + requesterUsername + " "}
    </Link>
  );
};
const RecommenderUsernameLink = (props) => {
  const { recommenderUsername } = props;
  return (
    <Link
      style={{ color: "#0645AD" }}
      to={`/user/${recommenderUsername}`}
    >
      {"@" + recommenderUsername + " "}
    </Link>
  );
};



export default function FeedRecommendationItem(props) {

  const renderUsernameLinks = (recommenderUsername, requesterUsername) => {
    if (recommenderUsername && requesterUsername && recommenderUsername === requesterUsername) {
      return (
        <span className="block text-gray-500 font-bold text-xs">
          <RecommenderUsernameLink recommenderUsername={recommenderUsername} />
        </span>
      )
    }
    if (recommenderUsername && requesterUsername && recommenderUsername !== requesterUsername) {
      return (
        <span className="block text-gray-500 font-bold text-xs">
          <RecommenderUsernameLink recommenderUsername={recommenderUsername} /> to <RequesterUsernameLink requesterUsername={requesterUsername} />
        </span>
      )
    }
  }
  return (
    <div>
      <Link to={`/request/${props.item.RequestId}`}>
        <div className="bg-white border-t-2 border-b-2 border-white px-4 pb-2 pt-1">
          <div className="py-1">
            <div className="grid grid-cols-3 gap-1">
              <div className="col-span-2 text-left justify-center flex flex-col pl-3">
                {renderUsernameLinks(props.item.RecommenderUsername, props.item.RequesterUsername)}
              </div>
              <div className="col-span-1 text-right justify-center flex flex-col pr-3">
                <span className="block text-gray-500 text-xs truncate">
                  {moment(
                    new Date(Date.parse(props.item.UpdateTime))
                  ).fromNow()}
                </span>
              </div>
            </div>
          </div>
          {/* <hr className="border-gray-300 border-opacity-50 mx-3 mb-1"></hr> */}
          <div>
            <div className="grid grid-cols-1">
              <div className=" text-left justify-center flex flex-col px-3">
                <span className="block text-black font-medium text-md truncate">
                  {props.item.LocationName}
                </span>
                <span className="block text-black text-xs truncate">
                  {props.item.Address}
                </span>
              </div>
              <div className=" text-left justify-center flex flex-col px-3 pt-1">
                <span className="block text-gray-500 text-xs">
                  {props.item.Details}
                </span>
              </div>
              {/* <div className=" text-right justify-center flex flex-col px-3">
              <span className="block text-gray-500 text-xl truncate">👉 </span>
            </div> */}
            </div>
          </div>
        </div>
      </Link>
      <hr className="border-indigo-200 mx-3"></hr>
    </div>
  );
}
