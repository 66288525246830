import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react";
import { useSpring, animated } from "react-spring";
import { MinusIcon } from "@heroicons/react/outline";
import { useDrag } from 'react-use-gesture'

const V_THRESHOLD = 0.3
const DEFAULT_EXPAND_HEIGHT = 300;

const MapDrawer = forwardRef((props, ref) => {
  const [expand, setExpand] = useState(false);
  const unexpandedHeight = props.unexpandedHeight || DEFAULT_EXPAND_HEIGHT;
  const { shouldNotExpand, children } = props;

  useImperativeHandle(ref, () => ({
    expandDrawer() {
      setExpand(true);
    },
    unexpandDrawer() {
      setExpand(false)
    }
  }));


  useEffect(() => {
    if (shouldNotExpand) {
      setExpand(false);
    }
  }, [props]);


  const springProps = useSpring({
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: expand ? 0 : window.innerHeight - unexpandedHeight,
    backgroundColor: "#ffffff",
    width: "w-screen",
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    overflowY: expand ? "auto" : "hidden",
    zIndex: 500
  });

  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (last) {
      // getting the swipe direction
      if (Math.abs(vx) > Math.abs(vy)) {
      } else {
        // swipe up is when vertical velocity is inferior to minus threshold
        if (vy < -V_THRESHOLD) {
          setExpand(true);
        }
        // swipe down is when vertical velocity is superior to threshold
        else if (vy > V_THRESHOLD) {
          setExpand(false);
        }
      }
    }
  });

  return (
    <animated.div style={springProps}>
      <div {...bind()} className="fixed w-full flex-1 bg-white border-2 flex items-center justify-center">
        <button onClick={() => setExpand(!expand)}>
          <MinusIcon className="h-8 w-8 md:h-12 md:w-12" />
        </button>
      </div>
      <div className="pt-10">
        {children}
      </div>
    </animated.div>
  )

});

export default MapDrawer;