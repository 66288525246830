import React from "react";
import { SaveIcon } from "@heroicons/react/solid";
import useCheckMobileScreen from "../../utils/MobileCheck";

function Suggest(props) {
  const isMobileView = useCheckMobileScreen();

  return (
    <div className={isMobileView ? "py-3 center" : "absolute w-full flex bottom-0 center"}>
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
      className="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-left text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Suggest
      <SaveIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
    </button>
  </div>
  );
}

export default Suggest;
