import React from "react";
import useCheckMobileScreen from "../../utils/MobileCheck";
import { PencilAltIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

function GiveARec(props) {
  const isMobileView = useCheckMobileScreen();

  return (
    <div
      className={
        isMobileView
          ? "xl:pt-4 xl:pb-10 xl:px-6 center px-2"
          : "absolute w-full flex bottom-0 center"
      }
    >
      <Link
        to={`/recommend/${props.requestId}`}
        className="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-left text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Add a recommendation
        <PencilAltIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
      </Link>
    </div>
  );
}

export default GiveARec;
