import React, { useContext, useState } from "react";
import { auth } from "../../firebase";
import UserContext from "../../contexts/UserContext";
import { useHistory } from "react-router";
import Logo from "../logo";
import { checkIfUsernameExists, createUser } from "../../api";
import { toast } from "react-toastify";

export default function SignUp(props) {
  const history = useHistory();
  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { userDispatch } = useContext(UserContext);

  const createUserWithEmailAndPasswordHandler = async (event) => {
    event.preventDefault();

    try {
      const usernameExistsResponse = await checkIfUsernameExists(
        username.toLowerCase()
      );
      if (usernameExistsResponse && usernameExistsResponse.result) {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(async () => {
            await auth.currentUser.updateProfile({
              displayName: displayName,
            });
            const token = await auth.currentUser.getIdToken();
            const userDetailsResponse = await createUser(
              {
                user_id: auth.currentUser.uid,
                username: username.toLowerCase(),
                email: email,
                display_name: displayName,
              },
              token
            );
            if (userDetailsResponse.executed) {
              userDispatch({
                type: "login",
                data: {
                  userAuth: auth.currentUser,
                  userDets: userDetailsResponse.user,
                  userRequests: userDetailsResponse.requests,
                  userRecommendations: userDetailsResponse.recommendations,
                  userFollowers: userDetailsResponse.followers,
                  userFollowings: userDetailsResponse.followings,
                },
              });
              window.analytics.identify(userDetailsResponse.user.UserId, {
                DisplayName: userDetailsResponse.user.DisplayName,
                Username: userDetailsResponse.user.Username,
              });
              window.analytics.track("Sign Up", {
                UserId: userDetailsResponse.user.UserId,
                DisplayName: userDetailsResponse.user.DisplayName,
                Username: userDetailsResponse.user.Username,
                Referrer: props.referrer,
                RequestDetails: props.requestDetails,
              });
              if (!props.stayOnPageOnSubmit) history.push(`/user/${username}`);
            }
          })
          .catch((e) => {
            console.error(e);
            toast.error(e.message);
          });
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <div className="flex-1 flex flex-col py-5 px-10 sm:px-10 lg:flex-none lg:px-10 xl:px-10">
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <Logo style={{ color: "black", margin: 0 }} />
        <div className="mt-6">
          <form action="#" method="POST" className="space-y-4">
            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="felix"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium text-gray-700"
              >
                Display Name
              </label>
              <div className="mt-1">
                <input
                  id="displayName"
                  name="displayName"
                  type="text"
                  required
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  placeholder="Felix Felicis"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="felix@feliz.app"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                onClick={(event) => {
                  createUserWithEmailAndPasswordHandler(event);
                }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign Up
              </button>
            </div>

            <div>
              <button
                type="submit"
                onClick={() => props.toggleForm()}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 bg-opacity-75 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Already have an account?
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
