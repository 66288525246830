import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import Maps from "../../components/map";
import AutoComplete from "../../components/autocomplete";
import PlaceDetails from "../../components/details/PlaceDetails";
import PlacesList from "../../components/list/PlacesList";
import Submit from "./Submit";
import Modal from "../../components/modal";
import Suggest from "./Suggest";
import AuthModal from "../../components/modal/AuthModal";
import NotesModal from "../../components/modal/NotesModal";
import MapDrawer from "../../components/mapdrawer";
import { useHistory, useParams } from "react-router-dom";
import RequestDetails from "../../components/requestdetails/RequestDetails";
import {
  getRequestDetails,
  createOrUpdateRecommendation,
  deleteRecommendation,
} from "../../api";
import {
  mapPlaceToRecommendationInput,
  mapRecommendationInputToPlace,
} from "../../mapper";
import UserContext from "../../contexts/UserContext";
import _ from "lodash";
import { toast } from "react-toastify";
import useCheckMobileScreen from "../../utils/MobileCheck";
import { usePrevious } from "../../hooks";

function Places() {
  const history = useHistory();
  const { requestId } = useParams();
  const [requestDetails, setRequestDetails] = useState();
  const [showAuthModal, setAuthModal] = useState(false);
  const [showNotesModal, setNotesModal] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [recommendedPlace, setRecommendedPlace] = useState();
  const [selectedIndexForNotes, setSelectedIndexForNotes] = useState();
  const renderMobileDrawer = recommendedPlace || recommendations.length > 0;
  const { user, userDispatch } = useContext(UserContext);
  const drawerRef = useRef();
  const isMobileView = useCheckMobileScreen();
  const previousRecommendationLength = usePrevious(recommendations.length);

  useEffect(async () => {
    const requestDetailsResponse = await getRequestDetails(requestId);
    if (requestDetailsResponse)
      setRequestDetails(requestDetailsResponse.request);

    if (user && user.userRecommendations) {
      const recs = user.userRecommendations.filter(
        (recommendation) => recommendation.RequestId === requestId
      );
      const userRecs = recs.map((rec) => mapRecommendationInputToPlace(rec));
      setRecommendations(userRecs);
    }
  }, [requestId, user]);

  useEffect(() => {
    if (!showNotesModal) {
      setSelectedIndexForNotes();
    }
  }, [showNotesModal]);

  useEffect(() => {
    if (
      previousRecommendationLength !== undefined &&
      recommendations.length > previousRecommendationLength
    ) {
      toggleNotesModal(recommendations.length - 1);
    }
  }, [recommendations, previousRecommendationLength]);

  const toggleNotesModal = useCallback((index) => {
    setNotesModal(true);
    setSelectedIndexForNotes(index);
  }, []);

  const addNote = useCallback(
    async (note) => {
      const recommendation = recommendations[selectedIndexForNotes];
      await createOrUpdateRecommendation({
        ...mapPlaceToRecommendationInput({
          ...recommendation,
          note,
        }),
        request_id: requestId,
      });
      setRecommendations(
        recommendations.map((recommendation, index) =>
          index === selectedIndexForNotes
            ? { ...recommendation, note }
            : recommendation
        )
      );
      setSelectedIndexForNotes();
      setNotesModal(false);
    },
    [
      recommendations,
      selectedIndexForNotes,
      setNotesModal,
      setSelectedIndexForNotes,
    ]
  );

  const addRecommendation = useCallback(
    async (recommendation) => {
      const response = await createOrUpdateRecommendation({
        ...mapPlaceToRecommendationInput(recommendation),
        request_id: requestId,
      });
      userDispatch({
        type: "newRecommendation",
        data: { userRecommendation: response.rec },
      });
      setRecommendations(recommendations.concat(recommendation));
      setRecommendedPlace();
      toast.success(`Recommended ${recommendation.name}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    },
    [setRecommendations, recommendations]
  );

  const removeRecommendation = useCallback(
    async (i) => {
      const recommendation = recommendations[i];
      await deleteRecommendation({
        location_id: recommendation.place_id,
        request_id: requestId,
      });
      setRecommendations(recommendations.filter((_, index) => index !== i));
      userDispatch({
        type: "removeRecommendation",
        data: {
          userRecommendation: {
            LocationId: recommendation.place_id,
            RequestId: requestId,
          },
        },
      });
      toast.success(`Removed ${recommendation.name}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    },
    [setRecommendations, recommendations]
  );

  return (
    <>
      <Modal open={showAuthModal} setOpen={setAuthModal}>
        <AuthModal referrer={"recommend"} requestDetails={requestDetails} />
      </Modal>

      {selectedIndexForNotes !== undefined && (
        <Modal open={showNotesModal} setOpen={setNotesModal}>
          <NotesModal
            onSave={addNote}
            place={recommendations[selectedIndexForNotes]}
          />
        </Modal>
      )}
      <div className="px-2 py-2 xl:hidden">
        {requestDetails && <RequestDetails requestDetails={requestDetails} />}
        <AutoComplete
          searchValue={recommendedPlace?.name}
          clear={!recommendedPlace}
          setSelectedPlace={
            !_.isEmpty(user.userAuth)
              ? setRecommendedPlace
              : () => setAuthModal(true)
          }
          requestDetails={requestDetails}
        />
        <Suggest
          disabled={!recommendedPlace}
          onClick={() => addRecommendation(recommendedPlace)}
        />
      </div>

      {renderMobileDrawer && (
        <div className="xl:hidden">
          <MapDrawer
            ref={drawerRef}
            shouldNotExpand={selectedIndexForNotes !== undefined}
            unexpandedHeight={50}
          >
            {!recommendedPlace && recommendations.length > 0 && (
              <div className="py-2 px-3 pb-0 sm:px-6 xl:px-5">
                <PlacesList
                  places={recommendations}
                  onRemove={removeRecommendation}
                  toggleNotes={toggleNotesModal}
                  onSelectPlace={
                    !_.isEmpty(user.userAuth)
                      ? setRecommendedPlace
                      : () => setAuthModal(true)
                  }
                />
                {!recommendedPlace && recommendations.length > 0 && (
                  <Submit
                    onClick={() => history.push(`/request/${requestId}`)}
                  />
                )}
              </div>
            )}
            {recommendedPlace && (
              <>
                <PlaceDetails place={recommendedPlace} />
                <div className="px-3">
                  <Suggest
                    onClick={() => addRecommendation(recommendedPlace)}
                  />
                </div>
              </>
            )}
          </MapDrawer>
        </div>
      )}

      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          {/* Start main area*/}
          <Maps
            locations={recommendations}
            selectedPlace={recommendedPlace}
            requestDetails={requestDetails}
            radius={requestDetails ? requestDetails.Radius : 0}
          />
          {/* End main area */}
        </main>
        <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-2/7 border-r border-gray-200 pb-10">
          {/* Start secondary column (hidden on smaller screens) */}
          <div className="overflow-y-auto">
            <>
              <div className="py-2 px-3 pb-2 sm:px-6 xl:px-5">
                {requestDetails && (
                  <RequestDetails requestDetails={requestDetails} />
                )}
                <AutoComplete
                  clear={!recommendedPlace}
                  setSelectedPlace={
                    !_.isEmpty(user.userAuth)
                      ? setRecommendedPlace
                      : () => setAuthModal(true)
                  }
                  requestDetails={requestDetails}
                  searchValue={recommendedPlace?.name}
                />
                {!recommendedPlace && recommendations.length > 0 && (
                  <>
                    <PlacesList
                      places={recommendations}
                      onRemove={removeRecommendation}
                      toggleNotes={toggleNotesModal}
                      onSelectPlace={
                        !_.isEmpty(user.userAuth)
                          ? setRecommendedPlace
                          : () => setAuthModal(true)
                      }
                    />
                  </>
                )}
              </div>
            </>
            {recommendedPlace && (
              <>
                <PlaceDetails place={recommendedPlace} />
                <Suggest onClick={() => addRecommendation(recommendedPlace)} />
              </>
            )}
          </div>
          {!recommendedPlace && recommendations.length > 0 && (
            <Submit onClick={() => history.push(`/request/${requestId}`)} />
          )}
          {/* End secondary column */}
        </aside>
      </div>
    </>
  );
}
export default Places;
