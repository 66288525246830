import React, { useState } from "react";
import Maps from "../../components/map";
import PlaceDetails from "../../components/details/PlaceDetails";
import Submit from "./Submit";
import PlaceRequestForm from "./PlaceRequestForm";
import MapDrawer from "../../components/mapdrawer";
import { useHistory } from "react-router";
import { createRequest } from "../../api";
import { toast } from "react-toastify";

function PlaceRequest() {
  const [radius, setRadius] = useState(5);
  const [details, setDetails] = useState("");
  const [selectedPlace, setSelectedPlace] = useState();
  const history = useHistory();

  const submitRequest = async (event) => {
    event.preventDefault();
    try {
      const response = await createRequest({
        location_name: selectedPlace.name,
        latitude: selectedPlace?.geometry?.location.lat(),
        longitude: selectedPlace?.geometry?.location.lng(),
        radius: radius,
        details: details,
      });
      if (response) {
        history.push(`/recommend/${response.request_id}`);
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <>
      <div className="px-2 xl:hidden">
        <PlaceRequestForm
          place={selectedPlace}
          setPlace={setSelectedPlace}
          radius={radius}
          setRadius={setRadius}
          details={details}
          setDetails={setDetails}
        >
          <Submit
            disabled={!selectedPlace}
            onClick={(event) => submitRequest(event)}
          />
        </PlaceRequestForm>
      </div>

      {selectedPlace && (
        <div className="xl:hidden">
          <MapDrawer unexpandedHeight={50}>
            <PlaceDetails place={selectedPlace} />
            <Submit
              containerClassnames={"py-4 px-6 center"}
              onClick={(event) => submitRequest(event)}
            />
          </MapDrawer>
        </div>
      )}

      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          {/* Start main area*/}
          <Maps
            selectedPlace={selectedPlace}
            hideButtons={true}
            radius={radius}
            isRequest={true}
          ></Maps>
          {/* End main area */}
        </main>
        <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-2/7 border-r border-gray-200 pb-10">
          {/* Start secondary column (hidden on smaller screens) */}
          <div className="overflow-y-auto mb-2">
            <PlaceRequestForm
              place={selectedPlace}
              setPlace={setSelectedPlace}
              radius={radius}
              setRadius={setRadius}
              details={details}
              setDetails={setDetails}
            />
            <hr className="border-gray-400 border-opacity-50 my-4"></hr>
            {selectedPlace && (
              <div className="pb-8">
                <PlaceDetails place={selectedPlace} />
              </div>
            )}
          </div>
          {/* End secondary column */}
          {selectedPlace && (
            <Submit
              containerClassnames={
                "absolute w-full bottom-0 center text-center"
              }
              onClick={(event) => submitRequest(event)}
            />
          )}
        </aside>
      </div>
    </>
  );
}
export default PlaceRequest;
