import React, { useContext, useState } from "react";
import { isEmpty } from "lodash";
import UserContext from "../../contexts/UserContext";
import DefaultProfileImage from "../defaultprofileimage";
import Modal from "../../components/modal";
import UserList from "../list/UserList";

export default function ProfileHeader(props) {
  const { user } = useContext(UserContext);
  const { userDets, userRequests, userRecommendations, userFollowers } =
    props.userProfile;
  const { follow, unfollow } = props;
  const isLoggedIn = !isEmpty(user?.userDets) && !isEmpty(user?.userAuth);
  const [isModalOpen, setModalState] = useState(false);

  return (
    <div>
      {isModalOpen && (
        <Modal open={isModalOpen} setOpen={setModalState}>
          <div style={{minWidth: "300px"}} className="py-10 px-8 min-w-50">
            <UserList headerTitle={"Followers:"} usernames={userFollowers.map(user => user.Username)} />
          </div>
        </Modal>
      )}
      <div className="grid grid-cols-9 gap-2 mx-6 mt-6">
        <div className="col-span-3 items-left bg-opacity-75 text-center justify-center flex flex-col py-1">
          <span>
            <DefaultProfileImage userDets={userDets} profileHeader={true} />
          </span>
        </div>

        <div className="col-span-2 bg-opacity-75 text-center justify-center flex flex-col py-1">
          <span className="block text-md md:text-lg">
            {userRequests.length}
          </span>
          <span className="block text-xxs md:text-xs">Requests</span>{" "}
        </div>

        <div className="col-span-2 bg-opacity-75 text-center justify-center flex flex-col py-1">
          <span className="block text-md md:text-lg">
            {userRecommendations.length}
          </span>
          <span className="block text-xxs md:text-xs">Rec's</span>
        </div>
        <div
          className="cursor-pointer hover:text-indigo-400 col-span-2 bg-opacity-75 text-center justify-center flex flex-col py-1">
          <button
            onClick={() => setModalState(true)}
            disabled={userFollowers?.length < 1}
          >
            <span
              className="block text-md md:text-lg">
              {userFollowers?.length}
            </span>
            <span className="block text-xxs md:text-xs">Followers</span>
          </button>
        </div>
      </div>
      <div className="block text-left font-bold text-xl md:text-2xl mx-6 leading-none mt-2">
        {userDets.DisplayName ? userDets.DisplayName : `@${userDets.Username}`}
      </div>

      <div className="col-span-5 bg-white-500 bg-opacity-75 justify-center flex flex-col mx-6 mt-0 pt-0">
        <span className="block text-sm text-gray-500">
          {userDets.DisplayName ? `@${userDets.Username}` : ""}
        </span>
      </div>
      <div className="grid grid-cols-8 gap-2 mx-6 mb-2 mt-2 ">
        {isLoggedIn &&
          user?.userAuth?.uid !== userDets.UserId &&
          user.userFollowings.filter(
            (userFollowing) => userFollowing.UserId === userDets.UserId
          ).length < 1 && (
            <div className="col-span-8 w-full bg-opacity-75 text-center justify-center items-center flex flex-col">
              <button
                onClick={() => follow()}
                type="button"
                className="inline-flex w-full justify-center items-center mx-2.5 px-4 py-1 border border-transparent text-sm md:text-base font-medium rounded text-indigo-700 bg-indigo-200 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center"
              >
                Follow
              </button>
            </div>
          )}
        {isLoggedIn &&
          user?.userAuth?.uid !== userDets.UserId &&
          user.userFollowings.filter(
            (userFollowing) => userFollowing.UserId === userDets.UserId
          ).length > 0 && (
            <div className="col-span-8 w-full bg-opacity-75 text-center justify-center items-center flex flex-col">
              <button
                onClick={() => unfollow()}
                type="button"
                className="inline-flex w-full justify-center items-center mx-2.5 px-4 py-1 border border-transparent text-sm md:text-base font-medium rounded text-indigo-700 bg-indigo-200 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center"
              >
                Unfollow
              </button>
            </div>
          )}
        {/* {
          user?.userAuth?.uid === userDets.UserId &&
          <div className="col-span-8 w-full bg-opacity-75 text-center justify-center items-center flex flex-col">
            <button
              onClick={() => {}}
              type="button"
              className="inline-flex w-full justify-center items-center mx-2.5 px-4 py-1 border border-transparent text-sm md:text-base font-medium rounded text-blue-700 bg-blue-200 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-center"
            >
              Edit Profile
              </button>
          </div>
        } */}
      </div>
      {/* <div className="grid grid-cols-1 gap-2 mt-1 mb-4 mx-2">
        <div className="bg-white-400 bg-opacity-75 flex flex-col px-3">
          <span className="block text-xs md:text-sm whitespace-pre-wrap">
            {userDets.Bio}
          </span>
        </div>
      </div> */}
    </div>
  );
}
