// export const BASE_BACKEND_URL = "http://localhost:8080";
export const BASE_BACKEND_URL =
  "https://ytm-backend-dot-feliz-finance.uc.r.appspot.com";

export const GOOGLE_MAPS_API_KEY = "AIzaSyANinJqWXpFqu8Ilb97zKJXtw-q4t2rrc0";

// User
export const GET_USER_DETAILS_ENDPOINT = `${BASE_BACKEND_URL}/users/get_user_details_by_user_id`;
export const GET_USER_DETAILS_BY_USERNAME_ENDPOINT = `${BASE_BACKEND_URL}/users/get_user_details`;
export const CHECK_IF_USERNAME_EXISTS_ENDPOINT = `${BASE_BACKEND_URL}/users/check_username_available`;
export const CREATE_USER_ENDPOINT = `${BASE_BACKEND_URL}/users/new_user`;
export const FOLLOW_USER_ENDPOINT = `${BASE_BACKEND_URL}/users/follow`;
export const UNFOLLOW_USER_ENDPOINT = `${BASE_BACKEND_URL}/users/unfollow`;

// Recommendations
export const CREATE_RECOMMENDATION_ENDPOINT = `${BASE_BACKEND_URL}/recommendations/new_recommendation`;
export const DELETE_RECOMMENDATION_ENDPOINT = `${BASE_BACKEND_URL}/recommendations/delete_recommendation`;
export const UPDATE_RECOMMENDATION_DETAILS_ENDPOINT = `${BASE_BACKEND_URL}/recommendations/update_recommendation_details`;

// Requests
export const GET_REQUEST_ENDPOINT = `${BASE_BACKEND_URL}/requests/get_request_details`;
export const GET_REQUEST_RESPONSES_ENDPOINT = `${BASE_BACKEND_URL}/requests/responses/`;
export const CREATE_REQUEST_ENDPOINT = `${BASE_BACKEND_URL}/requests/new_request`;

// Feed
export const GET_FEED_ENDPOINT = `${BASE_BACKEND_URL}/feed/global`;

export const VIEW_REQUEST_TYPE_RECS = "RECS";
export const VIEW_REQUEST_TYPE_NOTES = "NOTES";
