import React from "react";
import useCheckMobileScreen from "../../utils/MobileCheck";
import { PencilAltIcon } from "@heroicons/react/outline";

function Recommend(props) {
  const { onClick, hasRecommended } = props;
  const isMobileView = useCheckMobileScreen();

  return (
    <div
      className={
        isMobileView
          ? "xl:pt-4 xl:pb-10 xl:px-6 center px-2"
          : "absolute w-full flex bottom-0 center"
      }
    >
      <button
        onClick={onClick}
        className={hasRecommended ? "inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-left text-white hover:bg-red-700 focus:outline-none bg-red-500" : "bg-indigo-600 inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-left text-white hover:bg-indigo-700 focus:outline-none"}
      >
        {hasRecommended ? "Remove recommendation" : "Recommend"}
        <PencilAltIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
}

export default Recommend;
