import React from "react";

function Submit(props) {
  return (
    <div className={props.containerClassnames}>
      <button
        disabled={props.disabled}
        onClick={props.onClick}
        type="button"
        className="flex justify-center items-center w-full px-6 py-3 border border-transparent shadow-sm text-sm font-medium z-100 cursor-pointer text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Ask for Recommendations
    </button>
    </div>
  );
}

export default Submit;
