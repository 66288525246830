import React, { useState, useCallback, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { XIcon } from "@heroicons/react/solid";

function AutoComplete(props) {
  const [searchValue, setSearchValue] = useState("");
  const [autocomplete, setAutocomplete] = useState();
  const [bounds, setBounds] = useState();

  useEffect(() => {
    if (props.clear) {
      setSearchValue("");
    }
    if (props.requestDetails) {
      const { Radius, Latitude, Longitude } = props.requestDetails;
      const center = {
        lat: () => Latitude,
        lng: () => Longitude,
      };
      const radiusInMeters = 1609.34 * Radius;
      const distanceFromCenterToCorner = radiusInMeters * Math.sqrt(2.0);
      const bounds = new window.google.maps.LatLngBounds();
      const east = window.google.maps.geometry.spherical.computeOffset(
        center,
        distanceFromCenterToCorner,
        225.0
      );
      bounds.extend(east);
      const west = window.google.maps.geometry.spherical.computeOffset(
        center,
        distanceFromCenterToCorner,
        45
      );
      bounds.extend(west);
      setBounds(bounds);
    }

    if (props.searchValue && !searchValue) {
      setSearchValue(props.searchValue);
    }
  }, [props]);

  const handleSearchClear = useCallback(() => {
    setSearchValue("");
    props.setSelectedPlace();
  }, [props]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onAutocompleteLoads = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const options = {
    location: {
      lat: props.requestDetails?.Latitude,
      lng: props.requestDetails?.Longitude,
    },
    radius: props.requestDetails?.Radius * 1609.34,
    strictBounds: true,
  };
  const onPlaceChange = useCallback(() => {
    if (autocomplete) {
      const selectedPlace = autocomplete.getPlace();
      props.setSelectedPlace(selectedPlace);
      setSearchValue(selectedPlace.name);
    }
  }, [props, autocomplete]);

  return (
    <Autocomplete
      onLoad={onAutocompleteLoads}
      onPlaceChanged={onPlaceChange}
      options={props.requestDetails ? options : {}}
      bounds={bounds}
    >
      <div className="mt-1 relative rounded-md">
        <input
          onChange={handleSearchChange}
          value={searchValue}
          type="text"
          className="w-full rounded-md bg-white-200 text-black-700 leading-tight focus:outline-none py-2 px-2"
        />
        <button
          onClick={handleSearchClear}
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        >
          <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </Autocomplete>
  );
}

export default AutoComplete;
