import React, { useState, useEffect, useReducer } from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Landing from "./pages/Landing";
import { Switch, Route, Redirect } from "react-router-dom";
import { auth } from "./firebase";
import _ from "lodash";
import UserContext, { reducer, initialUserState } from "./contexts/UserContext";
import "./App.css";
import Recommend from "./pages/recommend";
import Navbar from "./components/navbar";
import { useLoadScript } from "@react-google-maps/api";
import Spinner from "./components/spinner";
import { useLocation } from "react-router";
import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";
import Request from "./pages/request";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Profile from "./pages/profile";
import { getUserDetails } from "./api";
import RequestResults from "./pages/requestresults";
import { toast } from "react-toastify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Feed from "./pages/feed/Feed";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import PlaceDetails from "./pages/placedetails";
import { GOOGLE_MAPS_API_KEY } from "./constants/constants";

const mapLibraries = ["places", "drawing", "geometry"];

library.add(fab, faComment);

function App() {
  const location = useLocation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: mapLibraries,
  });

  const [user, dispatch] = useReducer(reducer, initialUserState);
  const value = { user: user, userDispatch: dispatch };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (newUser) => {
      setIsLoading(true);
      if (newUser) {
        try {
          const response = await getUserDetails();
          if (response && response.executed) {
            dispatch({
              type: "login",
              data: {
                userAuth: auth.currentUser,
                userDets: response.user,
                userRequests: response.requests,
                userRecommendations: response.recommendations,
                userFollowers: response.followers,
                userFollowings: response.followings,
              },
            });
            console.log(response);
            window.analytics.identify(response.user.UserId, {
              DisplayName: response.user.DisplayName,
              Username: response.user.Username,
            });
          }
        } catch (e) {
          console.error(e);
          toast.error(e.message);
        }
      } else {
        dispatch({ type: "logout" });
        window.analytics.reset();
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (!isLoaded || isLoading) return <Spinner />;

  const renderNavbar = !(
    // (location.pathname === "/" && _.isEmpty(user.userAuth)) ||
    (
      location.pathname.toLocaleLowerCase() === "/auth/signin" ||
      location.pathname.toLocaleLowerCase() === "/auth/signup"
    )
  );

  return (
    <UserContext.Provider value={value}>
      <div className="App">
        {renderNavbar && <Navbar />}
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/landing">
            <Landing />
          </Route>
          <Route path="/auth/signin">
            <SignInPage />
          </Route>
          <Route path="/auth/signup">
            <SignUpPage />
          </Route>
          <Route path="/recommend/:requestId">
            <Recommend />
          </Route>
          <Route path="/request/:requestId">
            <RequestResults />
          </Route>
          <Route exact path="/request">
            <Request />
          </Route>
          <Route exact path="/feed">
            <Feed />
          </Route>
          <Route path="/user/:username">
            <Profile />
          </Route>
          <Route path="/place/:locationId/:requestId?">
            <PlaceDetails />
          </Route>
          <Route exact path="/nyc">
            <Redirect to={"request/0c3b583a-ea57-4ad0-8bac-a18501243b83"} />
          </Route>
          <Route exact path="/">
            <Feed />
            {/* {_.isEmpty(user.userAuth) ? <Home /> : <Feed />} */}
          </Route>
        </Switch>
        <ToastContainer />
      </div>
    </UserContext.Provider>
  );
}

export default App;
