import React, { useState, useContext, useEffect, useRef } from "react";
import Maps from "../../components/map";
import UserContext from "../../contexts/UserContext";
import MapDrawer from "../../components/mapdrawer";
import { useParams } from "react-router-dom";
import {
  getRequestDetails,
  createOrUpdateRecommendation,
  deleteRecommendation,
  getRequestResponses,
} from "../../api";
import RequestDetails from "../../components/requestdetails/RequestDetails";
import GiveARec from "./GiveARec";
import Modal from "../../components/modal";
import RequestResponseList from "../../components/list/RequestResponseList";
import UserList from "../../components/list/UserList";
import { useCallback } from "react";
import NotesList from "../../components/list/NotesList";
import AuthModal from "../../components/modal/AuthModal";
import {
  VIEW_REQUEST_TYPE_NOTES,
  VIEW_REQUEST_TYPE_RECS,
} from "../../constants/constants";
import useCheckMobileScreen from "../../utils/MobileCheck";

function RequestResults() {
  const { user, userDispatch } = useContext(UserContext);
  const { requestId } = useParams();
  const [requestDetails, setRequestDetails] = useState();
  const [responses, setResponses] = useState([]);
  const [isModalOpen, setModalState] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [showAuthModal, setAuthModal] = useState(false);
  const isMobileView = useCheckMobileScreen();
  const drawerRef = useRef();

  const setShowAuthModal = useCallback(() => {
    setAuthModal(true);
    if(isMobileView) {
      drawerRef.current.unexpandDrawer();
    }
  }, [drawerRef, isMobileView, setAuthModal]);

  useEffect(async () => {
    const requestDetailsResponse = await getRequestDetails(requestId);
    if (requestDetailsResponse)
      setRequestDetails(requestDetailsResponse.request);
    const requestResponsesResponse = await getRequestResponses(requestId);
    if (requestResponsesResponse) {
      const responsesArray = Object.values(
        requestResponsesResponse.output
      ).sort((a, b) => {
        return b.users.length - a.users.length;
      });
      setResponses(responsesArray);
    }
  }, [requestId]);

  useEffect(() => {
    const userRecMap = user.userRecommendations.reduce(
      (userRecMap, recommendation) => {
        userRecMap[recommendation["LocationId"]] = { ...recommendation };
        return userRecMap;
      },
      {}
    );

    const responsesWithUpdatedDetailList = responses.map((response) => {
      if (response.LocationId in userRecMap) {
        const newDetailsList = response.details_list.map((details) => {
          if (Object.keys(details)[0] === user.userDets.Username) {
            return {
              [user.userDets.Username]: userRecMap[response.LocationId].Details,
            };
          } else return { ...details };
        });

        return {
          ...response,
          details_list: newDetailsList,
        };
      }
      return response;
    });

    setResponses(responsesWithUpdatedDetailList);
  }, [user]);

  useEffect(async () => {
    if (!isModalOpen) {
      setSelectedRequest();
    }
  }, [isModalOpen]);

  const recommend = (LocationId) => {
    setResponses(
      responses.map((response) => {
        if (response.LocationId === LocationId) {
          return {
            ...response,
            users: [...response.users, user.userDets.Username],
            details_list: [
              ...response.details_list,
              { [user.userDets.Username]: "" },
            ],
            num_recommendations: response.num_recommendations + 1,
            user_recommendation: true,
          };
        } else return response;
      })
    );

    const response = responses.find(
      (response) => response.LocationId === LocationId
    );

    const body = {
      request_id: requestId,
      location_name: response.LocationName,
      location_id: response.LocationId,
      address: response.Address,
      latitude: response.Latitude,
      longitude: response.Longitude,
      details: "",
    };

    createOrUpdateRecommendation(body).then((response) => {
      if (response.executed) {
        userDispatch({
          type: "newRecommendation",
          data: { userRecommendation: response.rec },
        });
      }
    });
  };
  const unrecommend = (LocationId) => {
    setResponses(
      responses.map((response) => {
        if (response.LocationId === LocationId) {
          return {
            ...response,
            users: response.users.filter(
              (username) => username !== user.userDets.Username
            ),
            details_list: response.details_list.filter(
              (detail) => Object.keys(detail)[0] !== user.userDets.Username
            ),
            num_recommendations: response.num_recommendations - 1,
            user_recommendation: false,
          };
        } else return response;
      })
    );

    const body = {
      location_id: LocationId,
      request_id: requestId,
    };

    deleteRecommendation(body).then((response) => {
      if (response.executed) {
        userDispatch({
          type: "removeRecommendation",
          data: {
            userRecommendation: {
              LocationId: LocationId,
              RequestId: requestId,
            },
          },
        });
      }
    });
  };

  const renderModal = useCallback(() => {
    const { locationId, type } = selectedRequest;
    const responseItem = responses.find(
      (response) => response.LocationId === locationId
    );
    const usernames = responseItem.details_list.map(
      (obj) => Object.keys(obj)[0]
    );
    const enableEdit = Boolean(
      user.userRecommendations.find(
        (recommendation) =>
          recommendation.LocationId === locationId &&
          recommendation.RequestId === requestId
      )
    );

    return (
      <div className="py-10 mx-8">
        <div className="text-left text-3xl">{responseItem.LocationName}</div>
        <div className="text-left text-md text-gray-400">
          {responseItem.Address}
        </div>
        {type === VIEW_REQUEST_TYPE_RECS && <UserList usernames={usernames} />}
        {type === VIEW_REQUEST_TYPE_NOTES && (
          <NotesList
            requestId={requestId}
            locationId={locationId}
            notes={responseItem.details_list}
            enableEdit={enableEdit}
          />
        )}
      </div>
    );
  }, [user, selectedRequest, responses, requestId]);

  const viewRecommenders = useCallback((LocationId) => {
    setModalState(true);
    setSelectedRequest({
      locationId: LocationId,
      type: VIEW_REQUEST_TYPE_RECS,
    });
  }, []);

  const viewNotes = useCallback((LocationId) => {
    setModalState(true);
    setSelectedRequest({
      locationId: LocationId,
      type: VIEW_REQUEST_TYPE_NOTES,
    });
  }, []);

  return (
    <>
      <Modal open={showAuthModal} setOpen={setAuthModal}>
        <AuthModal referrer={"responses"} requestDetails={requestDetails} />
      </Modal>
      {isModalOpen && (
        <Modal open={isModalOpen} setOpen={setModalState}>
          {renderModal()}
        </Modal>
      )}

      <div className="py-2 px-3 pb-2 sm:px-6 xl:px-5 xl:hidden">
        {requestDetails && <RequestDetails requestDetails={requestDetails} />}
        <GiveARec requestId={requestId} />
      </div>

      <div className="xl:hidden">
        <MapDrawer ref={drawerRef} shouldNotExpand={selectedRequest} unexpandedHeight={50}>
          <RequestResponseList
            isClosed={selectedRequest}
            responses={responses}
            showAuth={setShowAuthModal}
            recommend={(LocationId) => recommend(LocationId)}
            unrecommend={(LocationId) => unrecommend(LocationId)}
            viewRecommenders={viewRecommenders}
            viewNotes={viewNotes}
          />
        </MapDrawer>
      </div>

      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
          <Maps
            userPlaces={responses}
            requestDetails={requestDetails}
            radius={requestDetails ? requestDetails.Radius : 0}
          ></Maps>
        </main>
        <aside className="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-2/7 border-r border-gray-200">
          <div className="py-2 px-3 pb-2 sm:px-6 xl:px-5">
            {requestDetails && (
              <RequestDetails requestDetails={requestDetails} />
            )}
          </div>
          <RequestResponseList
            showAuth={setShowAuthModal}
            responses={responses}
            recommend={(LocationId) => recommend(LocationId)}
            unrecommend={(LocationId) => unrecommend(LocationId)}
            viewRecommenders={viewRecommenders}
            viewNotes={viewNotes}
          />
          <GiveARec requestId={requestId} />
        </aside>
      </div>
    </>
  );
}
export default RequestResults;
