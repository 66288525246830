import React, { useContext } from "react";
import {
  StarIcon as SolidStar,
  UserIcon as SolidUser,
} from "@heroicons/react/solid";
import {
  StarIcon as OutlineStar,
  UserIcon as OutlineUser,
} from "@heroicons/react/outline";
import { PencilAltIcon as SolidPencil } from "@heroicons/react/solid";
import UserContext from "../../contexts/UserContext";
import { isEmpty } from "lodash";

export default function ResponseItem(props) {
  const { user } = useContext(UserContext);
  const {
    index,
    unrecommend,
    recommend,
    viewRecommenders,
    viewNotes,
    showAuth,
  } = props;
  const {
    Address,
    LocationId,
    LocationName,
    details_list,
    users,
    num_recommendations,
    RequestId,
  } = props.response;

  const numOfNotes = details_list.filter(
    (detail) => Object.values(detail)[0] !== ""
  ).length;
  const isNotLoggedIn = !user || isEmpty(user.userDets);
  const notesDisabled = numOfNotes < 1;

  return (
    <div className="bg-white border-t-2 border-b-2 border-white px-4">
      <div className="pb-2">
        <div className="grid grid-cols-5 gap-1">
          <div className="col-span-4 text-left justify-center flex flex-col pl-3">
            <span className="block text-black-600 font-bold text-md truncate">
              {String(index + 1).concat(". ", LocationName)}
            </span>
            <span className="block text-gray-600 text-sm truncate">
              {Address}
            </span>
          </div>
          {users.includes(user.userDets.Username) ? (
            <button
              onClick={() => unrecommend(LocationId)}
              className="inline  text-center justify-center flex flex-col px-3"
            >
              <span className="block text-indigo-600 hover:fill-current hover:text-indigo-700 text-sm">
                <SolidStar className="inline h-6 w-6" />
              </span>
            </button>
          ) : isNotLoggedIn ? (
            <button
              onClick={() => showAuth()}
              className="inline  text-center justify-center flex flex-col px-3"
            >
              <span className="block text-indigo-500 hover:fill-current hover:text-indigo-600 text-sm">
                <OutlineStar className="inline h-6 w-6" />
              </span>
            </button>
          ) : (
            <button
              onClick={() => recommend(LocationId)}
              className="inline  text-center justify-center flex flex-col px-3"
            >
              <span className="block text-indigo-500 hover:fill-current hover:text-indigo-600 text-sm">
                <OutlineStar className="inline h-6 w-6" />
              </span>
            </button>
          )}
        </div>
      </div>

      <div>
        <div className="grid grid-cols-2 px-4 mb-2 ">
          <div className="flex flex-col items-center">
            {users.includes(user.userDets.Username) ? (
              <div className="mb-4 px-2 items-center">
                <button
                  onClick={() => viewRecommenders(LocationId)}
                  className="flex text-center items-center text-sm text-indigo-600 font-bold sm:mt-0 hover:text-indigo-600"
                >
                  <SolidUser
                    className="flex-shrink-0 mr-1 h-5 w-5 text-indigo-600"
                    aria-hidden="true"
                  />
                  {num_recommendations} Rec's
                </button>
              </div>
            ) : (
              <div className="mb-4 px-2 items-center">
                <button
                  onClick={() => viewRecommenders(LocationId)}
                  className="flex text-center items-center text-sm text-indigo-500 sm:mt-0 hover:text-indigo-600"
                >
                  <OutlineUser
                    className="flex-shrink-0 mr-1 h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                  {num_recommendations} Rec's
                </button>
              </div>
            )}
          </div>

          <div className="flex flex-col text-center">
            {users.includes(user.userDets.Username) ? (
              <button onClick={() => viewNotes(LocationId)}>
                <span className="block text-indigo-600 text-sm font-bold hover:text-indigo-600">
                  <SolidPencil className="inline h-5 w-5 mr-1 text-indigo-600" />
                  {numOfNotes} Notes
                </span>
              </button>
            ) : (
              <button
                onClick={() => viewNotes(LocationId)}
                disabled={notesDisabled}
              >
                <span className="block text-indigo-500 text-sm hover:text-indigo-600">
                  <SolidPencil
                    className={"inline h-5 w-5 mr-1 text-indigo-500"}
                  />
                  {numOfNotes} Notes
                </span>
              </button>
            )}
          </div>
          {/* <div className="flex flex-col text-center">
            <Link to={`/place/${LocationId}/${RequestId}`}>
              <span className="block text-indigo-500 text-sm hover:text-indigo-600">
                <EyeIcon className={"inline h-5 w-5 mr-1 text-indigo-500"} />{" "}
                Details
              </span>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}
