import React, { useState, useCallback, useEffect } from "react";
import { toast } from 'react-toastify';

function NotesModal(props) {
  const [note, setNote] = useState();

  useEffect(() => {
    const note = props?.place?.note
    if(note) {
      setNote(note);
    }
  }, [props]);

  const saveNote = useCallback(() => { 
    props.onSave(note);
    toast.success(`Note added for ${props.place.name}`, {position: toast.POSITION.BOTTOM_CENTER, autoClose: 5000});
  }, [props, note]);

  const handleNoteChange = useCallback((event) => {
    setNote(event.target.value);
  }, [setNote]);

  return (
    <>
      <div className="flex-1 flex flex-col justify-center py-12 px-10 sm:px-10 lg:flex-none lg:px-10 xl:px-10">
        <div className="mx-auto w-full max-w-sm lg:w-96"></div>
        <h2 className="mt-6 text-2xl font-extrabold text-gray-900">
          {props.place.name}
        </h2>
        <div className="my-2 sm:mt-0 sm:col-span-2">
          <p className="my-1 text-sm text-gray-500">Tell us more!</p>
          <textarea
            id="tips"
            name="tips"
            rows={11}
            className="max-w-lg px-2 py-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
            defaultValue={note}
            onChange={handleNoteChange}
          />
        </div>
        <div>
          <button
            type="submit"
            onClick={() => saveNote()}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default NotesModal;