import React from "react";
import { ThumbUpIcon } from "@heroicons/react/solid";
import useCheckMobileScreen from "../../utils/MobileCheck";

function Submit(props) {
  const isMobileView = useCheckMobileScreen();

  return (
    <div className={isMobileView ? "pt-4 pb-10 px-6 center" : "absolute w-full flex bottom-0 center"}>
      <button
        onClick={props.onClick}
        type="button"
        className="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-left text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        See full results
        {/* <ThumbUpIcon className="ml-2 mr-1 h-5 w-5" aria-hidden="true" /> */}
      </button>
    </div>
  );
}

export default Submit;
