import { createContext } from "react";

var UserContext = createContext();
export const initialUserState = {
  userAuth: {},
  userDets: {},
  userRequests: {},
  userRecommendations: [],
  userFollowers: {},
  userFollowings: {},
};
export const reducer = (state, action) => {
  switch (action.type) {
    case "login": {
      return {
        userAuth: action.data.userAuth,
        userDets: action.data.userDets,
        userRequests: action.data.userRequests,
        userRecommendations: action.data.userRecommendations,
        userFollowers: action.data.userFollowers,
        userFollowings: action.data.userFollowings,
      };
    }

    case "logout": {
      return {
        userAuth: {},
        userDets: {},
        userRequests: {},
        userRecommendations: [],
        userFollowers: {},
        userFollowings: {},
      };
    }
    case "newRecommendation": {
      return {
        ...state,
        userRecommendations: [
          ...state.userRecommendations,
          action.data.userRecommendation,
        ],
      };
    }
    case "removeRecommendation": {
      return {
        ...state,
        userRecommendations: state.userRecommendations.filter(
          (recommendation) =>
            !(
              recommendation.LocationId ==
                action.data.userRecommendation.LocationId &&
              recommendation.RequestId ==
                action.data.userRecommendation.RequestId
            )
        ),
      };
    }
    case "setNote": {
      return {
        ...state,
        userRecommendations: state.userRecommendations.map((recommendation) =>
          recommendation.LocationId ===
            action.data.userRecommendation.LocationId &&
          recommendation.RequestId === action.data.userRecommendation.RequestId
            ? {
                ...recommendation,
                Details: action.data.userRecommendation.Details,
              }
            : recommendation
        ),
      };
    }
    case "follow": {
      return {
        ...state,
        userFollowings: [...state.userFollowings, action.data.userFollowing],
      };
    }
    case "unfollow": {
      return {
        ...state,
        userFollowings: state.userFollowings.filter(
          (following) => following.UserId !== action.data.userFollowing.UserId
        ),
      };
    }
    case "newRequest": {
      return {
        ...state,
        userRequests: [...state.userRequests, action.data.userRequest],
      };
    }
    case "removeRequest": {
      return {
        ...state,
        userRequests: state.userRequests.filter(
          (request) => request.RequestId !== action.data.userRequest.RequestId
        ),
      };
    }
    case "updateRecommendations": {
      return {
        ...state,
        userRecommendations: action.data.userRecommendations,
      };
    }
    case "updateRequests": {
      return {
        ...state,
        userRequests: action.data.userRequests,
      };
    }
    case "updateFollowers": {
      return {
        ...state,
        userFollowers: action.data.userFollowers,
      };
    }
    case "updateFollowings": {
      return {
        ...state,
        userFollowings: action.data.userFollowings,
      };
    }
    case "updateDets": {
      return {
        ...state,
        userDets: action.data.userDets,
      };
    }
    case "updateAuth": {
      return {
        ...state,
        userAuth: action.data.userAuth,
      };
    }
    default:
      return state;
  }
};
export default UserContext;
