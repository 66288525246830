import React from "react";
import cappadocia from "../../assets/cappadocia.jpeg";
import BackButton from "../../components/auth/BackButton";
import SignUp from "../../components/signup";
import { useHistory } from "react-router-dom";

export default function SignUpPage() {
  const history = useHistory();

  return (
    <div className="min-h-screen bg-white flex">
      <BackButton />
      <SignUp toggleForm={() => history.push("/auth/signin")} />
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={cappadocia}
          alt=""
        />
      </div>
    </div>
  );
}
