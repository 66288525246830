import React from "react";
import { PencilAltIcon } from "@heroicons/react/solid";

function Notes(props) {
  const { hasNotes } = props;
  const iconColor = hasNotes ? "text-blue-400" : "text-black-400"
  return (
    <button
      onClick={props.onClick}
    >
      <div className="flex pb-2">
        <PencilAltIcon className={"h-5 w-5 " + iconColor} />
        <p className={"pl-1 text-sm truncate " + iconColor}>Add details</p>
      </div>
    </button>
  )
}

export default Notes;