import React, { useState, useEffect, useCallback, useContext } from "react";
import Maps from "../../components/map";
import FeedListContainer from "../../components/feedlistcontainer";
import { getFeed } from "../../api";
import { GlobeIcon, QuestionMarkCircleIcon, UsersIcon, StarIcon } from '@heroicons/react/solid'
import UserContext from "../../contexts/UserContext";
import { isEmpty } from "lodash";
import { classNames } from "../../utils/Tailwind";

const tabs = [
  { name: 'All', title: 'Recent Activity', icon: GlobeIcon, },
  { name: 'Friends', title: 'Your Friends', icon: UsersIcon },
  { name: 'Requests', title: 'Requests', icon: QuestionMarkCircleIcon },
  { name: 'Recs', title: 'Recs', icon: StarIcon },
];

const tabTitle = {
  [tabs[0].name]: 'Recent Activity',
  [tabs[1].name]: 'Your Friends',
  [tabs[2].name]: 'Requests',
  [tabs[3].name]: 'Recs',
}


function Feed() {
  const { user } = useContext(UserContext);
  const [feedItems, setFeedItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabs[0].name);
  const userFollowingMap = !isEmpty(user.userDets) ? user?.userFollowings?.reduce((accumMap, following) => {
    accumMap[following.Username] = { ...following };
    return accumMap;
  }, {}) : {};
  useEffect(async () => {
    const feedResponse = await getFeed();
    if (feedResponse.executed) {
      setFeedItems(feedResponse.feed);
    }
  }, []);

  const filterListByTab = useCallback(() => {
    switch (selectedTab) {
      case tabs[0].name:
        return feedItems;
      case tabs[1].name:
        return feedItems.filter(item => (item.type === 'request' && item.RequesterUsername in userFollowingMap) || (item.type === 'recommendation' && item.RecommenderUsername in userFollowingMap));
      case tabs[2].name:
        return feedItems.filter(item => item.type === 'request');
      case tabs[3].name:
        return feedItems.filter(item => item.type === 'recommendation');
    }
  }, [selectedTab, feedItems]);

  const filteredFeedItems = filterListByTab();

  return (
    <>
      <div className="flex-1 relative z-0 flex overflow-hidden grid grid-cols-10 ">
        <main className="hidden col-span-7 flex-1 relative z-0 focus:outline-none lg:flex lg:order-last">
          {/* Start main area*/}
          <Maps
            userPlaces={feedItems.filter(
              (item) => item.type === "recommendation"
            )}
            userRequests={feedItems.filter((item) => item.type === "request")}
          >
          </Maps>
          {/* End main area */}
        </main>
        <aside className="col-span-10 lg:col-span-3 lg:order-first flex flex-col flex-shrink-0 flex-grow-1 border-r border-gray-200 overflow-y-auto">
          <div className="px-7 py-4 text-xl text-center text-indigo-500 font-bold">
            {tabTitle[selectedTab]}
          </div>
          <div>
            <div className="px-4">
              <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                {tabs.map((tab, tabIdx) => (
                  <button
                    onClick={() => setSelectedTab(tab.name)}
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.name === selectedTab ? 'bg-indigo-500' : 'hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center focus:z-10'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    <span
                      className="justify-center items-center flex"
                    >{<tab.icon
                      className={classNames(
                        tab.name === selectedTab ? 'text-white' : 'text-gray-400 group-hover:text-gray-500',
                        'h-6 w-6'
                      )}
                      aria-hidden="true"
                    />}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.name === selectedTab ? 'bg-indigo-500' : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                ))}
              </nav>
              {
                filteredFeedItems.length < 1 && selectedTab === tabs[1].name &&
                <div className="pt-4  text-lg text-indigo-500 text-center">
                  There's nothing here yet! Follow people you know to see what your friends are looking at.
                </div>
              }
            </div>
          </div>
          <FeedListContainer feedItems={filteredFeedItems}></FeedListContainer>
        </aside>
      </div>
    </>
  );
}
export default Feed;
