import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import useCheckMobileScreen from "../../utils/MobileCheck";
const mapClassName = "w-full flex-1 h-full";
// import * from "../../assets/clusterer";

const styles = require("./GoogleMapStyles.json")

const defaultGoogleMapsOptions = {
  mapTypeControl: false,
  styles:styles
};

const mobileGoogleMapsOptions = {
  ...defaultGoogleMapsOptions,
  mapTypeControlOptions: {
    position: 1,
  },
  streetViewControlOptions: {
    position: 3,
  },
  zoomControlOptions: {
    position: 7,
  },
  rotateControl: true,
  rotateControlOptions: {
    position: 1,
  },
  controlSize: 24,
};
//
const defaultCenter = {
  lat: 39.5,
  lng: -98.35,
};

const defaultZoom = 5;

function Maps(props) {
  const isMobileView = useCheckMobileScreen();
  const {
    selectedPlace,
    locations,
    radius,
    isRequest,
    requestDetails,
    userPlaces,
    userRequests,
    onMapLoad,
  } = props;
  const selectedPlaceLocation = selectedPlace?.geometry?.location;
  const [center, setCenter] = useState(
    () => props.defaultLocation || defaultCenter
  );
  const [zoom, setZoom] = useState(defaultZoom);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const onLoad = (circle) => {
    // console.log("Circle onLoad circle: ", circle);
  };
  const onUnmount = (circle) => {
    // console.log("Circle onUnmount circle: ", circle);
  };
  const circleOptions = {
    strokeColor: "#800080",
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "#800080",
    fillOpacity: 0.05,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: radius * 1609.34,
    zIndex: 1,
    optimized: false,
  };

  const clusterOptions = {
    optimized: false,
    // averageCenter: true,
    scale: 2,
    zIndex: 200,
    maxZoom: 12,
    minimumClusterSize: radius ? 4 : 2,
    imagePath:
      "https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m",
  };
  // const clusterOptions = {
  //   imagePath: "clusterer/m",
  // };

  const [activeMarker, setActiveMarker] = useState();

  const handleClearMarker = useCallback(() => {
    setActiveMarker(null);
  }, []);
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedMarker(null);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  useEffect(() => {
    if (selectedPlaceLocation && isRequest && radius) {
      setCenter(selectedPlaceLocation);
      setZoom(Math.round((17 - Math.log(radius * 1609.34)) / Math.log(2)));
    } else if (selectedPlaceLocation) {
      setCenter(selectedPlaceLocation);
      setZoom(18);
    } else if (requestDetails && radius) {
      setCenter({
        lat: requestDetails.Latitude,
        lng: requestDetails.Longitude,
      });
      setZoom(Math.round((17 - Math.log(radius * 1609.34)) / Math.log(2)));
    } else if (locations && locations.length > 0) {
      setZoom(defaultZoom);
    }
  }, [selectedPlaceLocation, locations, radius, isRequest, requestDetails]);

  const renderRequestCircles = useCallback(() => {
    if (userRequests) {
      return Object.values(userRequests).map((location, i) => (
        <Marker
          key={i}
          // optional
          // onLoad={onLoad}
          // optional
          // onUnmount={onUnmount}
          // required
          // center={{
          //   lat: -1 * selectedPlaceLocation.lat,
          //   lng: (selectedPlaceLocation.lng - 180) % 180,
          // }}
          icon={{
            path: "M271.346 79.721c-22.935-0.015-41.855 18.864-41.865 41.774 c-0.009 23.403 18.722 42.228 42.013 42.225c23.185-0.003 41.988-18.82 41.985-42.013C313.477 98.474 294.673 79.737 271.346 79.721 z M272.383 149.558c-3.299 0.027-5.461-2.08-5.474-5.332c-0.014-3.298 2.089-5.447 5.347-5.464c3.22-0.017 5.461 2.198 5.462 5.396 C277.719 147.317 275.53 149.533 272.383 149.558z M285.293 116.914c-1.209 2.626-3.042 4.78-4.971 6.863 c-1.687 1.822-2.979 3.816-3.573 6.273c-0.584 2.42-3.066 3.882-5.458 3.37c-2.205-0.472-3.502-2.64-3.185-5.167 c0.463-3.685 2.492-6.495 4.892-9.143c2.326-2.567 3.984-5.44 3.5-9.089c-0.124-0.936-0.336-1.906-0.739-2.749 c-1.062-2.216-3.772-2.551-5.337-0.646c-0.645 0.785-1.099 1.762-1.484 2.714c-0.667 1.65-1.924 2.258-3.578 2.284 c-1.199 0.019-2.399 0.026-3.598-0.001c-2.296-0.052-3.059-1.019-2.647-3.311c1.273-7.108 6.19-11.073 15.502-11.072 c1.893 0.015 5.314 0.775 8.059 3.398C286.663 104.45 287.757 111.562 285.293 116.914z",
            fillColor: "#feb2b2",
            fillOpacity: 0.5,
            scale: 0.3,
            strokeColor: "#f56565",
            strokeWeight: 2,
            strokeOpacity: 0.6,
            size: new window.google.maps.Size(16, 16),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(256, 128),
          }}
          position={{
            lat: location.Latitude,
            lng: location.Longitude,
          }}
          onClick={() => {
            setSelectedMarker({
              location: location,
            });
          }}
          onMouseOver={() => {
            setSelectedMarker({
              location: location,
            });
          }}
          // required
          options={{
            optimized: false,
            //   strokeColor: "#800080",
            //   strokeOpacity: 0.5,
            //   strokeWeight: 2,
            //   fillColor: "#800080",
            //   fillOpacity: 0.05,
            //   clickable: false,
            //   draggable: false,
            //   editable: false,
            //   visible: true,
            //   radius: location.Radius * 1609.34,
            zIndex: -1,
          }}
        />
      ));
    }
  }, [userRequests]);
  const renderMarkers = useCallback(() => {
    if (userPlaces) {
      return (
        <MarkerClusterer options={clusterOptions}>
          {(clusterer) =>
            Object.values(userPlaces).map((location, i) =>
              location.user_recommendation || true ? (
                <Marker
                  clusterer={clusterer}
                  // onClick={() => setActiveMarker(location)}
                  icon={{
                    path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                    fillColor: "#A5B4FC",
                    fillOpacity: 0.9,
                    scale: 1.5,
                    strokeColor: "#818CF8",
                    strokeWeight: 2,
                    size: new window.google.maps.Size(16, 16),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(8, 8),
                  }}
                  options={{ optimized: false, zIndex: 20 }}
                  title={location.LocationName}
                  // label={location.LocationName}
                  name={location.LocationName}
                  key={i}
                  position={{
                    lat: location.Latitude,
                    lng: location.Longitude,
                  }}
                  onClick={() => {
                    setSelectedMarker({
                      location: location,
                    });
                  }}
                  onMouseOver={() => {
                    setSelectedMarker({
                      location: location,
                    });
                  }}
                />
              ) : (
                <Marker
                  clusterer={clusterer}
                  // onClick={() => setActiveMarker(location)}
                  icon={{
                    url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
                    size: new window.google.maps.Size(20, 32),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(0, 32),
                  }}
                  key={i}
                  position={{
                    lat: location.Latitude,
                    lng: location.Longitude,
                  }}
                />
              )
            )
          }
        </MarkerClusterer>
      );
    }
    if (selectedPlaceLocation) {
      return <Marker position={selectedPlaceLocation} />;
    }

    if (locations) {
      return locations.map((location, i) => (
        <Marker
          onClick={() => setActiveMarker(location)}
          key={i}
          position={location.geometry.location}
        />
      ));
    }
  }, [userPlaces, locations, selectedPlaceLocation, clusterOptions]);

  return (
    <>
      {props.children}
      <GoogleMap
        center={center}
        zoom={zoom}
        clickableIcons={false}
        options={
          isMobileView ? mobileGoogleMapsOptions : defaultGoogleMapsOptions
        }
        mapContainerClassName={mapClassName}
        tilt={45}
        onLoad={onMapLoad ? onMapLoad : () => {}}
      >
        {renderMarkers()}
        {renderRequestCircles()}
        {selectedMarker && (
          <InfoWindow
            style={{ "pointer-events": "none", background: "white" }}
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
            position={{
              lat: selectedMarker.location.Latitude,
              lng: selectedMarker.location.Longitude,
            }}
          >
            <div>
              <Link
                style={{
                  color: "#0645AD",
                  background: "white",
                  outline: "none",
                  boxShadow: "none",
                }}
                to={`/request/${selectedMarker.location.RequestId}`}
              >
                <h3 className="text-sm font-bold">
                  📍 {selectedMarker.location.LocationName}
                </h3>
              </Link>
              {/* <h5>{selectedMarker.location.Address}</h5> */}
              {!selectedMarker.location.RecommenderUsername &&
                selectedMarker.location.RequesterUsername && (
                  <div>
                    <h5>
                      {"Request by "}
                      <Link
                        style={{ color: "#0645AD", background: "white" }}
                        to={`/user/${selectedMarker.location.RequesterUsername}`}
                      >
                        @{selectedMarker.location.RequesterUsername}
                      </Link>
                    </h5>
                  </div>
                )}
              {selectedMarker.location.RecommenderUsername && (
                <div>
                  <h5>
                    💡{" Rec by "}
                    <Link
                      style={{ color: "#0645AD", background: "white" }}
                      to={`/user/${selectedMarker.location.RecommenderUsername}`}
                    >
                      @{selectedMarker.location.RecommenderUsername}
                    </Link>
                  </h5>
                  {selectedMarker.location.Details && (
                    <div>
                      <br />
                      <h5
                        style={{
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          display: "-webkit-box",
                          "-webkit-line-clamp": "2",
                          "-webkit-box-orient": "vertical",
                        }}
                      >
                        {selectedMarker.location.Details}
                      </h5>
                    </div>
                  )}
                </div>
              )}
            </div>
          </InfoWindow>
        )}
        {activeMarker && (
          <InfoWindow
            position={activeMarker.geometry.location}
            onCloseClick={handleClearMarker}
          >
            <div className>{activeMarker.name}</div>
          </InfoWindow>
        )}
        {radius && selectedPlaceLocation && isRequest ? (
          <Circle
            // optional
            onLoad={onLoad}
            // optional
            onUnmount={onUnmount}
            // required
            // center={{
            //   lat: -1 * selectedPlaceLocation.lat,
            //   lng: (selectedPlaceLocation.lng - 180) % 180,
            // }}
            center={selectedPlaceLocation}
            // required
            options={circleOptions}
          />
        ) : (
          <></>
        )}
        {radius && requestDetails ? (
          <Circle
            // optional
            onLoad={onLoad}
            // optional
            onUnmount={onUnmount}
            // required
            // center={{
            //   lat: -1 * selectedPlaceLocation.lat,
            //   lng: (selectedPlaceLocation.lng - 180) % 180,
            // }}
            center={{
              lat: requestDetails.Latitude,
              lng: requestDetails.Longitude,
            }}
            // required
            options={circleOptions}
          />
        ) : (
          <></>
        )}
      </GoogleMap>
    </>
  );
}

export default React.memo(Maps);
