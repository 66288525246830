import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import ResponseItem from "../responseitem";

function RequestResponseList(props) {
  useEffect(() => {
    if (props.isClosed) {
      window.scrollTo(0, 0);
    }
  }, [props])

  return (
    <div className="overflow-y-auto pb-10">
      {props.responses?.map((response, i) => (
        <ResponseItem
          key={i}
          index={i}
          showAuth={() => props.showAuth()}
          response={response}
          recommend={(LocationId) => props.recommend(LocationId)}
          unrecommend={(LocationId) => props.unrecommend(LocationId)}
          viewRecommenders={props.viewRecommenders}
          viewNotes={props.viewNotes}
        />
      ))}
      {isEmpty(props.responses) && (
        <div className="bg-white pt-2 pb-4 mb-2">
          <div className="grid grid-cols-1 gap-2 mt-2">
            <div className="text-center justify-center flex flex-col px-3">
              <span className="block text-indigo-500 font-bold text-md italic">
                No Responses Yet!
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestResponseList;
