import React from "react";
import RequestItem from "../requestitem";
import { isEmpty } from "lodash";
import { ClipboardListIcon } from "@heroicons/react/solid";


const HorizontalLine = () => {
  return (
    <hr className="border-grey-300 border-opacity-100 mx-4 my-4" />
  )
}
function RequestList(props) {
  return (
    <div className="overflow-y-auto">
      {props.requests?.map((request, i) => (
        <div key={i}>
          <RequestItem request={request} />
          <HorizontalLine />
        </div>
      ))}
      {isEmpty(props.requests) && (
        <>
          <HorizontalLine />
          <div className="flex items-center justify-center mt-2">
            <div className="items-center text-center justify-center flex-row flex">
                <ClipboardListIcon className="align-center stroke-current text-indigo-600 w-10 h-10" />
              <span className="inline text-indigo-600 font-bold text-md">
                No Requests Yet!
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default RequestList;
