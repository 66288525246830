import React, { useState, useContext, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import UserContext from "../../contexts/UserContext";
import { updateRecommendationDetails } from "../../api";
import _ from "lodash";
import DefaultProfileImage from "../defaultprofileimage";

const NoteEdit = (props) => {
  const { user, userDispatch } = useContext(UserContext);
  const [note, setNote] = useState();

  useEffect(() => {
    const { notes } = props;
    const filteredNotes = notes.filter((note) => {
      const notesUsername = Object.keys(note)[0];
      return user.userDets.Username === notesUsername;
    });

    if (filteredNotes.length > 0) {
      const note = Object.values(filteredNotes[0])[0];
      setNote(note);
    }
  }, [user, props]);

  const saveNote = useCallback(async () => {
    const { requestId, locationId } = props;

    // make api call to update
    const saveNoteResponse = await updateRecommendationDetails({
      request_id: requestId,
      location_id: locationId,
      user_id: user.userDets.UserId,
      details: note,
    });

    if (saveNoteResponse.executed) {
      toast.success(`Note saved!`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      userDispatch({
        type: "setNote",
        data: {
          userRecommendation: {
            RequestId: requestId,
            LocationId: locationId,
            Details: note,
          },
        },
      });
    } else {
      toast.error(`Error saving note try again...`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  }, [props, note, userDispatch, user]);

  const handleNoteChange = useCallback(
    (event) => {
      setNote(event.target.value);
    },
    [setNote]
  );

  return (
    user && !_.isEmpty(user.userDets) &&
    <>
      <div className="my-2 sm:mt-0 sm:col-span-2">
        <p className="my-1 w-100 text-sm text-gray-500">Tell us more!</p>
        <textarea
          id="tips"
          name="tips"
          rows={3}
          className="px-2 py-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
          defaultValue={note}
          onChange={handleNoteChange}
        />
      </div>
      <button
        onClick={saveNote}
        className="bg-indigo-600 p-2 hover:text-indigo-500 focus:outline-none focus:ring-2  rounded focus:ring-indigo-500 text-white py-2"
      >
        Recommend
      </button>
    </>
  );
};

function NotesList(props) {
  const { notes, enableEdit } = props;

  return (
    <div>
      <div className="text-left py-3 text-2xl text-black-400">
        {"Tips from others:"}
      </div>
      <ul className="divide-y divide-gray-200">
        {notes.map((note, i) => {
          const username = Object.keys(note)[0];
          const userNote = Object.values(note)[0];
          if (!userNote) return "";
          return (
            <li key={i} className="py-4">
              <div className="flex space-x-3">
                <DefaultProfileImage  userDets={{Username: username}}/>
                <div className="flex-1 space-y-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">{username}</h3>
                    {/* <p className="text-sm text-gray-500">{"25 Minutes Ago"}</p> */}
                  </div>
                  <p className="text-sm text-gray-500">{userNote}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {enableEdit && <NoteEdit {...props} />}
    </div>
  );
}

export default NotesList;
