import React from "react";
import AutoComplete from "../../components/autocomplete";
import { XIcon } from "@heroicons/react/solid";
import useCheckMobileScreen from "../../utils/MobileCheck";


function PlaceRequestForm(props) {
  const { place, setDetails, details, setPlace, radius, setRadius } = props;
  const isMobileView = useCheckMobileScreen();

  return (
    <div className="py-2 px-3 pb-0 sm:px-6 lg:px-5">
      {!isMobileView && <div
        className="text-center text-xl text-indigo-500 font-bold">Ask for Recommendations</div>}
      <div className="py-2">
        <label
          htmlFor="Location"
          className="block text-sm font-medium text-gray-700"
        >
          Location
        </label>
        <AutoComplete
          clear={!place}
          setSelectedPlace={setPlace}
        />
      </div>

      <div className="py-2">
        <label
          htmlFor="radius"
          className="block text-sm font-medium text-gray-700"
        >
          Radius (Miles)
      </label>
        <div className="mt-1 relative rounded-md">
          <input
            onChange={(e) => setRadius(e.target.value)}
            placeholder="Enter Radius (Miles)"
            value={radius}
            type="number"
            name="radius"
            id="radius"
            className="w-full rounded-md bg-white-200 text-black-700 leading-tight focus:outline-none py-2 px-2"
          />
        </div>
      </div>

      <div className="pt-2">

        <label
          htmlFor="details"
          className="block text-sm font-medium text-gray-700"
        >
          Details
        </label>
        <div className="mt-1 relative rounded-md">
          <textarea
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Add more details about your request"
            value={details}
            type="text"
            name="details"
            id="deails"
            className="w-full block rounded-md bg-white-200 text-black-700 leading-tight focus:outline-none py-2 px-2"
          />
          <button
            onClick={() => {
              setDetails("");
            }}
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          >
            <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      </div>
      {props.children && <div className="pt-3 pb-2">
        {props.children}
      </div>}
    </div>
  );
}

export default PlaceRequestForm;