import React from "react";
import "./Logo.css";

function Logo(props) {
  return (
    <div className="text-center">
      <p className="logo" style={{...props.style}}>you tell me</p>
    </div>
  )
}

export default Logo;